<template lang="pug">
#MyFirstSlideshow
  .eg-theme-agrume
    .eg-slideshow
      slide(v-for="slide in availableSlides"
        :enter="getTransition(slide, 'enter')"
        :leave="getTransition(slide, 'leave')"
        :enterNext="getTransition(slide, 'enterNext')"
        :enterPrev="getTransition(slide, 'enterPrev')"
        :leaveNext="getTransition(slide, 'leaveNext')"
        :leavePrev="getTransition(slide, 'leavePrev')"
        :steps="((slide.steps == undefined )? 1 : slide.steps)"
        :class='`slide-${slide.name}`'
        :textColor="textColor"
        v-bind:key="slide.name"
      )
        router-view(v-bind:step="step")
        #app2
          v-row(
              class="position-absolute bottom-0 right-0"
              :style="{color: textColor }" 
            )
            v-btn-toggle(
              v-model="nav"
            )
              v-btn(@click="previousSlide" value="left" :color="secondaryColorHex")
                v-icon(:color="buttonTextColorHex") mdi-chevron-left
              v-btn(@click="nextSlide" value="center" :color="secondaryColorHex")
                v-icon(:color="buttonTextColorHex") mdi-chevron-right
              v-btn(@click="gotoExternalPage" value="right" :color="secondaryColorHex")
                p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Powered by 
                  span(class="text-none font-weight-bold") CheqqMate
      component(class="background" 
        :is="selectedSvg" 
        :primaryColor="primaryColor" 
        :secondaryColor="getColorVariants[1]" 
        :tertiaryColor="getColorVariants[0]"
      )
</template>

<script>
/* eslint-disable */
import { Slideshow } from "eagle.js";
import { mapState, mapMutations } from "vuex";
import bg1Svg from '../templates/sprinkle.vue';
import bg2Svg from '../templates/simple-shiny.vue';
import bg3Svg from '../templates/meteor.vue';
import bg4Svg from '../templates/animated-shape.vue';
import bg5Svg from '../templates/wave.vue';
export default {
  mixins: [Slideshow],
  components: {
    bg1Svg
  },
  props: {
    primaryColor: {
      type: String,
      required: true
    },
    bg: {
      type: String,
      required: true
    },
  
  },
  data() {
    return {
      nav: 'center',
      id2: '', 
      text: 'center',
      text1: 'center',
      slide: 'cover',
      selectedSvg: bg1Svg, // Default SVG bg
    };
  },
  computed: {
    ...mapState({
      availableSlides: "slides"
    }),
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    textColor() {
      return this.$store.state.textColor;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
  },

  watch: {
    currentSlideIndex: "updateUrl",

    $route: "updateSlides",
    bg(v) {
        if (v) {
          this.changeSvg(v);
        }
      },
    primaryColor(v) {
      if (v) {
        this.changeColor(v);
      }
    }
  },
  methods: {
    hello() {
      alert("hello");
    },
    previousSlide() {
      this.$emit("previous");
    },
    nextSlide() {
      this.$emit("next");
    },
    gotoExternalPage() {
      window.open('https://www.cheqqmate.com', '_blank');
    },
    getTransition: function(slide, type) {
      const defaultTransitions = {
        enter: "fadeIn",
        leave: "fadeOut",
        enterPrev: "fadeInLeft",
        enterNext: "fadeInRight",
        leavePrev: "fadeOutRight",
        leaveNext: "fadeOutLeft"
      };

      switch (type) {
        case "enter":
        case "leave":
          return slide[type] === undefined
            ? defaultTransitions[type]
            : slide[type] === null
            ? null
            : slide[type];
          break;

        default:
          if (slide.enter === undefined && slide.leave === undefined) {
            return slide[type] === undefined
              ? defaultTransitions[type]
              : slide[type] === null
              ? null
              : slide[type];
          } else {
            return null;
          }
          break;
      }
    },
    ...mapMutations({
      setCurrentIndex: "setCurrent"
    }),
    updateUrl(step) {
      //get current url with query or params
      const { name } = this.availableSlides[step - 1];
      if (name) {
        this.$router.push({
          name,
          query: this.$route.query // Pass the id as a parameter
        });
      }

    },
    updateSlides(route) {
      const index =
        this.availableSlides.findIndex(slide => slide.name === route.name) + 1;

      this.setCurrentIndex(index);
      this.currentSlideIndex = index;
    },
    changeSvg(bgName) {
      switch (bgName) {
        case 'bg1':
          this.selectedSvg = bg1Svg;
          break;
        case 'bg2':
          this.selectedSvg = bg2Svg;
          break;
        case 'bg3':
          this.selectedSvg = bg3Svg;
          break;
        case 'bg4':
          this.selectedSvg = bg4Svg;
          break;
        case 'bg5':
          this.selectedSvg = bg5Svg;
          break;
        default:
          this.selectedSvg = null;
          break;
      }
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    submit() {
      alert("submit");
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },
    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },
    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },
    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      return [lighterShade, darkerShade];
    }
  }
};
</script>
<style lang="scss">
.background {
  width: 100vw;
  height: 100vh;
}
#app2 {
  width: 4% ;
  // height: 100vh;
}
</style>