

<template>

  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
      <g mask="url(&quot;#SvgjsMask1026&quot;)" fill="none">
          <rect width="1440" height="560" x="0" y="0" :fill="primaryColor"></rect>
          <use xlink:href="#SvgjsSymbol1033" x="0" y="0"></use>
          <use xlink:href="#SvgjsSymbol1033" x="720" y="0"></use>
      </g>
      <defs>
          <mask id="SvgjsMask1026">
              <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
          <path d="M-1 0 a1 1 0 1 0 2 0 a1 1 0 1 0 -2 0z" id="SvgjsPath1027"></path>
          <path d="M-3 0 a3 3 0 1 0 6 0 a3 3 0 1 0 -6 0z" id="SvgjsPath1032"></path>
          <path d="M-5 0 a5 5 0 1 0 10 0 a5 5 0 1 0 -10 0z" id="SvgjsPath1028"></path>
          <path d="M2 -2 L-2 2z" id="SvgjsPath1031"></path>
          <path d="M6 -6 L-6 6z" id="SvgjsPath1029"></path>
          <path d="M30 -30 L-30 30z" id="SvgjsPath1030"></path>
      </defs>
      <symbol id="SvgjsSymbol1033">
          <use xlink:href="#SvgjsPath1027" x="30" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="30" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="30" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="30" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="30" y="270" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1029" x="30" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="30" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="30" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="30" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="30" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="90" y="30" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1031" x="90" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="90" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="90" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="90" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="90" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="90" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="90" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="90" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="90" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="150" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="150" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="150" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="150" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="150" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="150" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="150" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="150" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="150" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="150" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="210" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="210" y="90" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1029" x="210" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="210" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="210" y="270" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1027" x="210" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="210" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="210" y="450" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1031" x="210" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="210" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="270" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="270" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="270" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="270" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="270" y="270" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1028" x="270" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="270" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="270" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="270" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="270" y="570" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="330" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="330" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="330" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="330" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="330" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="390" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="390" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="390" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="390" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="390" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="390" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="390" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="390" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="390" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="390" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="450" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="450" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="450" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="450" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="450" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="450" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="450" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="450" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="450" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="450" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="510" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="510" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="510" y="150" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1032" x="510" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="510" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="510" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="510" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="510" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="510" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="510" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="570" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="570" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="570" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="570" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="570" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="630" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="630" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="630" y="150" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="630" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="630" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="630" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="630" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="630" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="630" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="630" y="570" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="690" y="30" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="690" y="90" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1030" x="690" y="150" :stroke="secondaryColor" stroke-width="3"></use>
          <use xlink:href="#SvgjsPath1029" x="690" y="210" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1028" x="690" y="270" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="690" y="330" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1027" x="690" y="390" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1032" x="690" y="450" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1031" x="690" y="510" :stroke="secondaryColor"></use>
          <use xlink:href="#SvgjsPath1029" x="690" y="570" :stroke="secondaryColor"></use>
      </symbol>
  </svg>
</template>
  
<script>
export default {
  name: "Sprinkle",
  props: {
    primaryColor: {
      type: String,
      default: "#0e2a47"
    },
    secondaryColor: {
      type: String,
      default: "#1c538e"
    },
    tertiaryColor: {
      type: String,
      default: "#113255"
    }
  }
};
</script>