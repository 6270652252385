

<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
        <g mask="url(&quot;#SvgjsMask1000&quot;)" fill="none">
            <rect width="1440" height="560" x="0" y="0" :fill="primaryColor"></rect>
            <path d="M 0,235 C 57.6,215.6 172.8,139.6 288,138 C 403.2,136.4 460.8,249.4 576,227 C 691.2,204.6 748.8,30.2 864,26 C 979.2,21.8 1036.8,187 1152,206 C 1267.2,225 1382.4,138 1440,121L1440 560L0 560z" :fill="tertiaryColor"></path>
            <path d="M 0,430 C 96,412.4 288,341.8 480,342 C 672,342.2 768,432 960,431 C 1152,430 1344,355.8 1440,337L1440 560L0 560z" :fill="secondaryColor"></path>
        </g>
        <defs>
            <mask id="SvgjsMask1000">
                <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
        </defs>
    </svg>
  </template>
  
  <script>
  export default {
    name: "Wave",
    props: {
      primaryColor: {
        type: String,
        default: "#0e2a47"
      },
      secondaryColor: {
        type: String,
        default: "#0b2239"
      },
      tertiaryColor: {
        type: String,
        default: "#113255"
      }
    }
  };
  </script>