

<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
        <g mask="url(&quot;#SvgjsMask1000&quot;)" fill="none">
            <rect width="1440" height="560" x="0" y="0" :fill="primaryColor"></rect>
            <path d="M0,639.776C116.529,623.279,188.387,510.796,288.382,448.731C390.509,385.343,548.233,382.408,593.833,271.194C639.71,159.304,515.299,48.021,501.964,-72.171C487.945,-198.529,577.43,-335.292,514.3,-445.643C450.635,-556.929,312.257,-620.164,184.258,-627.525C63.127,-634.491,-28.254,-525.787,-141.933,-483.381C-244.735,-445.033,-361.792,-454.777,-450.806,-390.626C-553.104,-316.901,-653.944,-221.287,-677.493,-97.409C-701.276,27.699,-633.828,149.649,-574.98,262.585C-518.613,370.76,-448.1,471.647,-346.108,538.555C-243.524,605.851,-121.476,656.973,0,639.776" :fill="secondaryColor"></path>
            <path d="M1440 1004.058C1524.913 1001.2180000000001 1608.1970000000001 978.854 1679.118 932.0740000000001 1749.511 885.642 1807.218 820.255 1837.796 741.667 1867.441 665.477 1874.493 578.397 1846.636 501.53499999999997 1820.854 430.399 1739.629 400.39300000000003 1692.619 341.105 1642.524 277.925 1638.323 170.279 1562.4180000000001 143.08300000000003 1487.159 116.11900000000003 1411.982 182.80399999999997 1338.469 214.21800000000002 1273.729 241.88299999999998 1209.619 267.73 1158.195 315.815 1102.38 368.006 1055.66 428.773 1031.493 501.266 1004.037 583.626 982.5 673.854 1011.567 755.659 1041.517 839.95 1112.988 903.5550000000001 1190.376 948.423 1265.651 992.066 1353.037 1006.967 1440 1004.058" :fill="tertiaryColor"></path>
        </g>
        <defs>
            <mask id="SvgjsMask1000">
                <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
        </defs>
    </svg>
  </template>
  
  <script>
  export default {
    name: "SimpleShiny",
    props: {
      primaryColor: {
        type: String,
        default: "#0e2a47"
      },
      secondaryColor: {
        type: String,
        default: "#0b2239"
      },
      tertiaryColor: {
        type: String,
        default: "#113255"
      }
    }
  };
  </script>