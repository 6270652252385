<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-form(v-model="valid")
    v-row
      v-col(:style="{color: textColorHex, fontSize: '24px' }")
        v-row
        div.d-flex.align-start.text-wrap
          //- p(:style="{color: textColorHex, fontSize: '21px' }") {{ getSlide.id+1 }}
          v-icon.mt-1.mx-n4(large :color="textColorHex")(left) mdi-menu-right
          p.mx-3(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}
        v-form(ref="form" v-model="valid")
          v-text-field(v-model="contactDetails.name" label="Name" :dark="contrast" outlined :backgroundColor="hexToRgba(secondaryColorHex)" :style="labelStyle" v-bind:input-style="inputStyle" class="text-input" @input="setAnswer(contactDetails.name)")
          v-text-field(v-if="!contactDetails.isHidden" v-model="contactDetails.email" :rules="emailRules" label="E-mail" :dark="contrast" outlined :backgroundColor="hexToRgba(secondaryColorHex)" :style="labelStyle" v-bind:input-style="inputStyle" class="text-input" @input="setAnswer(contactDetails.name)")
          v-col(
            class="mt-n4 shrink ml-n2"
            cols="12"
            lg="3"
            md="4"
            sm="7"
          ) 
            v-alert(
              v-if="getErrors[0]"
              class="shake"
              dense
              outlined
              type="error") {{getErrors[0]}}           
            v-btn(
              v-else
              :color="secondaryColorHex"
              raised 
              elevation="0" 
              class="font-weight-regular text-none" 
              @click="submit(getSlide.responseIsRequired)"
              :loading="loading"
            ) 
              p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Submit
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
import { nanoid } from 'nanoid';

const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

export default {
  name: "MultiField",
  props: {
    hasError: {
      type: Boolean,
      default: false
    },
    submitAnswer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      slide: 'multi-field',
      valid: false,
      emailRules: [
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      errorMessages: [],
      answer: '',
      responseUpdated: false,
    };
  },
  watch: {
    contactDetails: {
      handler: function (newVal) {
        this.$store.commit('SET_CONTACT_DETAILS', newVal);
      },
      deep: true,
    },
    hasError(e) {
      if (e) {
        this.errorMessages = ['Fill in required field(s).'];
      }
    },
    submitAnswer(e) {
      if (e) {
        this.submit(this.getSlide.responseIsRequired);
      }
    },
    "getSlide.answer": {
      handler() {
        this.errorMessages = [];
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters(["getSlide"]),
    embedded() {
      return this.$store.state.embedded;
    },
    contactDetails() {
      return this.$store.state.contactDetails || {};
    },
    getErrors() {
        return this.errorMessages;
      },
    textColorHex() {
      return this.$store.state.textColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    contrast(){
      return this.$store.state.contrast;
    },
    contactId() {
      return this.$store.state.contactId;
    },
    labelStyle() {
      return {
        color: this.dynamicLabelColor,
        opacity: 1
      };
    },
    inputStyle() {
      return {
        color: this.dynamicInputColor
      };
    },
    dynamicLabelColor() {
      return this.secondaryColorHex;
    },
    dynamicInputColor() {
      return this.secondaryColorHex;
    }
  },
  created() {
    this.$store.commit('SET_CONTACT_ID', this.generateId());
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
        return true;
      }

      return false;
    },
    submit(isRequired) {
      if(isRequired){
        if (this.$refs.form.validate()) {
          // Form is valid, proceed with submission
          this.registerContact();
          this.errorMessages = []; // Clear error messages
        } 
        else {
          // Form is invalid, show validation errors
          this.errorMessages = ['This field is required.'];
        }    
      } else {
        // Form is not required, proceed with submission
        this.registerContact();
        this.errorMessages = []; // Clear error messages 
      }      
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    generateId: () => nanoid(10),
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerContact() {
        // if getSlide.response do nothing
        if (this.getSlide.response && !this.responseUpdated) {
          this.nextSlide();
          return;
        }
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "ContactId": this.responseUpdated ? this.getSlide.response.ContactId : this.contactId?this.contactId:-1,
              "Name": this.contactDetails.name,
              "Email": this.contactDetails.email,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/contact`,postData).then((
          this.loading = false,
          this.getSlide.response = postData,
          this.nextSlide()
        )).catch((error) => {
          // console.log(error);
          this.loading = false;
        })
    },
    setAnswer(answer) {
      this.answer = answer;
      this.$store.commit("ADD_ANSWER_TO_SLIDE", answer);
      this.errorMessages = [];

      // if user has already submitted, but updates the answer, update the responseUpdate 
      if (this.getSlide.response) {
        this.responseUpdated = true;
      }
    },
    hexToRgba(hex) {
      if (!hex) return null;
      if (hex.length === 4) {
        var extendedHex = '#';
        for (var i = 1; i < hex.length; i++) {
          extendedHex += hex[i] + hex[i];
        }
        hex = extendedHex;
      }
      var bigint = parseInt(hex.replace('#', ''), 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;
      var a = 0.2;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
  },
};
</script>
<style >
.text-input .v-label {
  font-size: 18px !important;
  color: inherit !important;
  opacity: 1 !important;
}

.text-input .v-text-field__slot input {
  font-size: 18px !important;
  color: inherit !important;
}
.shake {
    animation: shake 0.25s;
  }
</style>