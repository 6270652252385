

<template>
  <svg v-if="version == 1" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect width="1440" height="560" :fill="primaryColor"></rect>
    <g>
      <g transform="translate(1018 8)">
        <path d="M0 -179.6L155.6 -89.8L155.6 89.8L0 179.6L-155.6 89.8L-155.6 -89.8Z" :fill="secondaryColor"></path>
      </g>
      <g transform="translate(283 550)">
        <path d="M0 -170L147.2 -85L147.2 85L0 170L-147.2 85L-147.2 -85Z" :fill="secondaryColor"></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="version == 2" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect width="1440" height="560" :fill="primaryColor"></rect>
    <g>
      <g transform="translate(1437 185)">
        <path d="M0 -179.6L155.6 -89.8L155.6 89.8L0 179.6L-155.6 89.8L-155.6 -89.8Z" :fill="secondaryColor"></path>
      </g>
      <g transform="translate(295 503)">
        <path d="M0 -170L147.2 -85L147.2 85L0 170L-147.2 85L-147.2 -85Z" :fill="secondaryColor"></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="version == 3" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect width="1440" height="560" :fill="primaryColor"></rect>
    <g>
      <g transform="translate(963 99)">
        <path d="M0 -179.6L155.6 -89.8L155.6 89.8L0 179.6L-155.6 89.8L-155.6 -89.8Z" :fill="secondaryColor"></path>
      </g>
      <g transform="translate(455 549)">
        <path d="M0 -170L147.2 -85L147.2 85L0 170L-147.2 85L-147.2 -85Z" :fill="secondaryColor"></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="version == 4" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect width="1440" height="560" :fill="primaryColor"></rect>
    <g>
      <g transform="translate(272 61)">
        <path d="M0 -179.6L155.6 -89.8L155.6 89.8L0 179.6L-155.6 89.8L-155.6 -89.8Z" :fill="secondaryColor"></path>
      </g>
      <g transform="translate(1432 221)">
        <path d="M0 -170L147.2 -85L147.2 85L0 170L-147.2 85L-147.2 -85Z" :fill="secondaryColor"></path>
      </g>
    </g>
  </svg>
  <svg v-else-if="version == 5" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect width="1440" height="560" :fill="primaryColor"></rect>
    <g>
      <g transform="translate(1209 149)">
        <path d="M0 -179.6L155.6 -89.8L155.6 89.8L0 179.6L-155.6 89.8L-155.6 -89.8Z" :fill="secondaryColor"></path>
      </g>
      <g transform="translate(1430 456)">
        <path d="M0 -170L147.2 -85L147.2 85L0 170L-147.2 85L-147.2 -85Z" :fill="secondaryColor"></path>
      </g>
    </g>
  </svg>
</template>
  
<script>
export default {
  name: "polygon-scatter",
  props: {
    primaryColor: {
      type: String,
      default: "#0e2a47"
    },
    secondaryColor: {
      type: String,
      default: "#0b2239"
    },
    tertiaryColor: {
      type: String,
      default: "#113255"
    },
    version: {
      type: Number,
      default: 2
    }
  }
};
</script>
<style scoped>.parent-container {
  width: 100%;
  height: 100vh;
  /* or any other height you need */
}

#visual {
  width: 100%;
  height: 100%;
}</style>