<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-row
    v-col(:style="{color: textColorHex, fontSize: '24px' }")
      v-row
      div.d-flex.align-start.text-wrap
        p(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.id+1 }}
        v-icon.mt-1.mx-n2(large :color="textColorHex")(left) mdi-menu-right
        p.mx-2(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}

      v-rating(
        v-model="getSlide.answer"
        :label="getSlide.placeholder"
        :background-color="textColorHex"
        :color="textColorHex"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        half-icon="mdi-star-half-full"
        hover
        dense
        class="ml-n1"
        large
        :dark="contrast"
        @input="setAnswer(getSlide.answer)"
      )
      v-col(
        class="shrink ml-n2"
        cols="12"
        lg="3"
        md="4"
        sm="7"
      ) 
        v-alert(
          v-if="getErrors[0]"
          class="shake"
          dense
          outlined
          type="error") {{getErrors[0]}}      
        v-btn(v-else :color="secondaryColorHex" raised elevation="0" class="font-weight-regular text-none" @click="submit(getSlide.responseIsRequired)") 
            p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Submit
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from 'axios';

  const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

  export default {
    name: "Rating",
    props: {
      hasError: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        slide: 'rating',
        errorMessages: [],
        answer: '',
        responseUpdated: false,
      };
    },
    mounted() {
      if (!this.getSlide.answer) {
        this.$store.commit("ADD_ANSWER_TO_SLIDE", null);
      }
    },
    watch: {
      hasError(e) {
        if (e) {
          this.errorMessages = ['Please select an option.'];
        }
      },
    },    
    computed: {
      ...mapGetters(["getSlide"]),
      getErrors() {
        return this.errorMessages;
      },
      textColorHex() {
        return this.$store.state.textColorHex;
      },
      buttonTextColorHex() {
        return this.$store.state.buttonTextColorHex;
      },
      secondaryColor() {
        return this.$store.state.secondaryColor;
      },
      secondaryColorHex() {
        return this.$store.state.secondaryColorHex;
      },
      contrast(){
        return this.$store.state.contrast;
      },
      contactId() {
        return this.$store.state.contactId;
      }
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true;
        }

        return false;
      },
      setAnswer(answer) {
        this.answer = answer;
        this.$store.commit("ADD_ANSWER_TO_SLIDE", answer);
        this.errorMessages = [];

        // if user has already submitted, but updates the answer, update the responseUpdate 
        if (this.getSlide.response) {
          this.responseUpdated = true;
        }
      },
      submit(isRequired) {
      if(isRequired){
        //if user has not selected an answer or dna-db has not been updated
        if (this.answer || this.getSlide.response) {
          // Form is valid, proceed with submission
          this.registerFormTransaction();
          this.errorMessages = []; // Clear error messages
        } 
        else {
          // Form is invalid, show validation errors
          this.errorMessages = ['This field is required.'];
        }    
      } else {
        // Form is not required, proceed with submission
        this.registerFormTransaction();
        this.errorMessages = []; // Clear error messages 
      }      
    },
      nextSlide() {
        this.$emit("start-slides");
      },
      sqlConvertDate (date) {
        return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
      },
      registerFormTransaction() {
        // if getSlide.response do nothing
        if (this.getSlide.response && !this.responseUpdated) {
          this.nextSlide();
          return;
        }
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "FormId": this.$store.state.form.id,
              "ContactId": this.responseUpdated ? this.getSlide.response.ContactId : this.contactId?this.contactId:-1,
              "QuestionId": this.getSlide.id,
              "Answer": this.getSlide.answer,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/formTransaction`,postData).then(() => {
          this.getSlide.response = postData,
          this.nextSlide();
        })
      },
    },
  };
  </script>
  
  <style scoped>
  .slide-FirstPage {
    background-color: black;
  }
  .question-number {
    color: #D53E59; /* pink darken-1 */
    font-size: 18px;
  }
  .question {
    color: #616161; /* grey darken-2 */
    font-size: 24px;
  
  }
  .button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
  }
  .shake {
    animation: shake 0.25s;
  }
</style>
  