

<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
      <g mask="url(&quot;#SvgjsMask1000&quot;)" fill="none">
          <rect width="1440" height="560" x="0" y="0" :fill="primaryColor"></rect>
          <path d="M952.7789521090433 419.6690618446741L867.5437018939065 401.5517501258606 849.4263901750929 486.7870003409975 934.6616403902298 504.904312059811z" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float1"></path>
          <path d="M970.66 168.63 a169.94 169.94 0 1 0 339.88 0 a169.94 169.94 0 1 0 -339.88 0z" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float3"></path>
          <path d="M1216.234027515513 278.25893002247403L1322.406477775944 330.0426940223743 1268.0177915154131 172.08647976204287z" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float3"></path>
          <path d="M-26.985,382.557C-9.638,382.368,9.729,381.283,18.851,366.527C28.299,351.244,23.185,331.841,13.862,316.481C4.939,301.78,-9.796,291.542,-26.985,291.012C-45.146,290.452,-62.848,298.388,-72.444,313.818C-82.588,330.13,-85.176,351.521,-74.58,367.543C-64.745,382.415,-44.814,382.751,-26.985,382.557" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float2"></path>
          <path d="M-49.89 302.85 a143.85 143.85 0 1 0 287.7 0 a143.85 143.85 0 1 0 -287.7 0z" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float3"></path>
          <path d="M684.0500619568277 293.16852530143933L792.6936365117027 312.32531877607545 767.1070394462193 139.78156020644477z" :fill="secondaryColor + 0o6 + 0o6" class="triangle-float2"></path>
      </g>
      <defs>
          <mask id="SvgjsMask1000">
              <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
      </defs>
  </svg>
  </template>
  
  <script>
  export default {
    name: "AnimatedShape",
    props: {
      primaryColor: {
        type: String,
        default: "#0e2a47"
      },
      secondaryColor: {
        type: String,
        default: "#0b2239"
      },
      tertiaryColor: {
        type: String,
        default: "#113255"
      }
    }
  };
  </script>
  <style>
  @keyframes float1 {
          0%{transform: translate(0, 0)}
          50%{transform: translate(-10px, 0)}
          100%{transform: translate(0, 0)}
      }

      .triangle-float1 {
          animation: float1 5s infinite;
      }

      @keyframes float2 {
          0%{transform: translate(0, 0)}
          50%{transform: translate(-5px, -5px)}
          100%{transform: translate(0, 0)}
      }

      .triangle-float2 {
          animation: float2 4s infinite;
      }

      @keyframes float3 {
          0%{transform: translate(0, 0)}
          50%{transform: translate(0, -10px)}
          100%{transform: translate(0, 0)}
      }

      .triangle-float3 {
          animation: float3 6s infinite;
      }
</style>