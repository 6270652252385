import Vue from "vue";
import App from "./App";
import VueMeta from 'vue-meta';

import Eagle, { Options, CodeBlock } from "eagle.js";
import "animate.css";
import hljs from "highlight.js";

import router from "@/router";
import store from "@/store";

import "@/theme.css";
import vuetify from './plugins/vuetify';

Vue.use(Eagle);
Vue.use(VueMeta);
Eagle.use(CodeBlock);
Vue.config.productionTip = false;
Options.hljs = hljs;

new Vue({
  router,
  store,
  el: "#app",
  components: { App },
  vuetify,
  render: h => h("App")
});
