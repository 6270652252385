<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-row
    v-col(class="question")
      v-row
        p(:style="{color: textColorHex, fontSize: '18px' }" class="mt-2 mr-2") {{ getSlide.id+1}}
          v-icon(:color="textColorHex" class="mt-n1")(left) mdi-arrow-right
        p(:style="{color: textColorHex, fontSize: '24px' }") {{ getSlide.question }}
      v-textarea(v-model="getSlide.answer" :label="getSlide.placeholder" outlined :dark="contrast" :color="textColorHex")
      v-btn(:color="secondaryColorHex" raised elevation="4" class="mt-4 font-weight-regular text-none" @click="submit") 
        p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") OK
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from 'axios';

  const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

  export default {
    name: "TextArea",
    data() {
      return {
        slide: 'text-area',
      };
    },
    computed: {
      ...mapGetters(["getSlide"]),
      textColorHex() {
        return this.$store.state.textColorHex;
      },
      buttonTextColorHex() {
        return this.$store.state.buttonTextColorHex;
      },
      secondaryColor() {
        return this.$store.state.secondaryColor;
      },
      contrast(){
        return this.$store.state.contrast;
      },
      contactId() {
        return this.$store.state.contactId;
      }
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true;
        }

        return false;
      },
      submit() {
      // alert("submit");
      this.registerFormTransaction();
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerFormTransaction() {
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "FormId": this.$store.state.form.id,
              "ContactId": this.contactId?this.contactId:-1,
              "QuestionId": this.getSlide.id,
              "Answer": this.getSlide.answer,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/formTransaction`,postData).then(() => {
          this.nextSlide();
        })
    },
    },
  };
  </script>
  
  <style scoped>
  .slide-FirstPage {
    background-color: black;
  }
  .question-number {
    color: #D53E59; /* pink darken-1 */
    font-size: 18px;
  }
  .question {
    color: #616161; /* grey darken-2 */
    font-size: 24px;
  
  }
  .button {
    color: white;
    font-size: 16px;
    padding: 10px 20px;
  }
</style>