<template lang="pug">
#MyFirstSlideshow
  .eg-theme-agrume
    .eg-slideshow
      v-progress-linear(
        v-if='!isLoading'
        :value="progress"
        :color="buttonTextColorHex"
      )
      //- p(class="text-caption") statusCode {{ statusCode}}
      //- p(class="text-caption") showLandingPage {{ showLandingPage}}
      slide(v-for="(page, index) in availableSlidePages" 
        :key="index"
        :textColor="textColor"  
      )
        //- only show slides if not showing landing page
        component(
          :is="currentPageSlide" 
          v-if="!showLandingPage && statusCode !==404 " 
          @start-slides="nextPage()" 
          :hasError="hasError"
          :submitAnswer="submitAnswer"
        )
        v-row(
            v-if='!isLoading && statusCode !==404'
            class="position-absolute bottom-0 right-0"
            :style="{color: textColor }" 
          )
          v-btn-toggle(
            v-model="nav"
            v-if='currentSlidePageIndex >= 0 && currentSlidePageIndex !== 99'
            dense
          )
            v-btn(@click="prevPage" value="left" :color="secondaryColorHex")
              v-icon(:color="buttonTextColorHex") mdi-chevron-left
            v-btn(@click="beforeNextPage" value="center" :color="secondaryColorHex")
              v-icon(:color="buttonTextColorHex") mdi-chevron-right
            v-btn(v-if="form.type === 'quizDetails'" value="right" :color="secondaryColorHex")
              v-icon(:color="buttonTextColorHex") mdi-podium
          v-btn(v-if='currentSlidePageIndex >= 0 && currentSlidePageIndex !== 99' @click="gotoExternalPage" elevation="0" :color="secondaryColorHex" class="ml-2")
            p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Powered by 
              span(class="text-none font-weight-bold") CheqqMate

          v-btn-toggle(
            v-model="nav"
            v-if='currentSlidePageIndex === 99'
            dense
          )
            v-btn(@click="prevPage" value="left" :color="secondaryColorHex")
              v-icon(:color="buttonTextColorHex") mdi-chevron-left
          v-btn(v-if='currentSlidePageIndex === 99' @click="gotoExternalPage" class="ml-2" elevation="0" :color="secondaryColorHex")
            p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Powered by 
              span(class="text-none font-weight-bold") CheqqMate
      //- loading page - FIXME: revert back bg0?
      div(v-if='bg === "bg0" && !showLandingPage && statusCode !==404'
        :style="backgroundImageStyle"
        class="background"
      )
      //- landing page
      div(v-else-if='showLandingPage'
        :style="backgroundImageStyle"
        class="background header-landing"
      )
        v-avatar(
          size="50"
          rounded
          bordered
          border-radius="8"
          class="ma-4"
        )
          v-img(
            src="../assets/CheqqMateLargeLogo.png" 
            tile                   
            contain
            max-height="50px"
          )
        //- form
        v-row(class="mt-n4")
          v-col(cols="3" class="text-center")
          v-col(cols="6" class="mt-12 text-center" :style="{fontSize: '24px' }")
            p(class="text-h3 text-none mt-4 font-weight-black text-center" :style="{color: '#D53E59' }") The simplest way to create 
                    span(class="text-h3 text-none font-weight-black text-animation" :style="{color: '#D53E59' }") {{ animatedText }}
            span(class="text-h3 text-none font-weight-black text-center" :style="{color: '#D53E59' }") within your Community
            h5(class="mt-4 text-none font-weight-bold text-center grey--text text--darken-2") Enter ID
              v-row
               v-col(cols="2" class="text-center")
               v-col(cols="8" class="text-center")
                  v-text-field(
                    v-model="itemId" 
                    append-icon="mdi-arrow-right-circle" 
                    single-line color="grey darken-2" 
                    outlined 
                    backgroundColor="grey lighten-2" 
                    class="mt-2 text-input"
                    @click:append="submit"
                    )
              v-col(cols="2" class="text-center")
          v-col(cols="3" class="text-center")

          //- paragraph
          v-row(class="mt-n16 mb-4")
            v-col(cols="3" class="text-center")
            v-col(cols="6" class="mt-12 text-center" :style="{fontSize: '24px' }")
              span(class="text-none mt-4 font-weight-regular text-center grey--text text--darken-2") Grow your community and drive member engagement with our forms, polls and quizzes. We have +100 templates to get you started.  
            v-col(cols="3" class="text-center")
          //- buttons
        v-btn-toggle(
          class="ml-2 lower-right-quadrant"
          dense
        )
          v-btn(value="left" color="grey lighten-1")
            //- v-icon( color="white") mdi-text-box
            p(class="text-caption text-none ml-1 mt-4 font-weight-bold grey--text text--darken-3") Forms
          v-btn(value="center" color="grey lighten-1")
            //- v-icon( color="white") mdi-poll
            p(class="text-caption text-none ml-1 mt-4 font-weight-bold grey--text text--darken-3") Polls
          v-btn(value="center" color="grey lighten-1")
            //- v-icon( color="white") mdi-help-box
            p(class="text-caption text-none ml-1 mt-4 font-weight-bold grey--text text--darken-3") Quizzes
          v-btn(@click="gotoExternalPage" elevation="0" color="primary" bottom right)
                p(class="text-body-1 text-none mt-4 font-weight-regular text--white") Powered by 
                  span(class="text-none font-weight-bold") CheqqMate
      //- 404 page
      div(v-else-if='statusCode == 404'
        :style="backgroundImageStyle"
        class="background"
      )
        v-row(class="mt-n1 mb-2")
          v-col(cols="3" class="text-center")
          v-col(cols="6" class="mt-12 text-center" :style="{fontSize: '24px' }")
            v-avatar(size="200"  class="mx-auto mt-8 mb-n4" style="justify-content: center; align-items: center; display: flex;")
                v-icon(:size="150" color="grey") mdi-emoticon-sad-outline
            span(class="text-h2 text-none font-weight-black text-center grey--text" :style="{color: '#D53E59' }") {{ statusCode }}
            h5(class="mt-8 text-none font-weight-bold text-center grey--text text--lighten-1") Page not found
            h6(class="mt-4 text-none font-weight-bold text-center grey--text") The page you are looking for might have been removed or is temporarily unavailable. Go back, or head over to 
                        span(class="text-none font-weight-black text-center grey--text text--darken-1") app.cheqqmate.com 
                        span(class="text-none font-weight-bold text-center grey--text") to enter another ID.
            v-btn(x-large raised elevation="0" color="grey lighten-1" class="mt-8 font-weight-regular text-none" @click="submit") 
              p(class="text-h6 text-none mt-4 font-weight-bold grey--text text--darken-2") Return
      div(v-else)
        component(
          class="background" 
          :is="selectedSvg" 
          :primaryColor="primaryColor" 
          :secondaryColor="getColorVariants[1]" 
          :tertiaryColor="getColorVariants[0]"
          :version="bgVersion"
        )
</template>

<script>
/* eslint-disable */
import { Slideshow } from "eagle.js";
import { mapState, mapMutations, mapGetters } from "vuex";
import bg0Svg from '../templates/solid.vue';
import bg1Svg from '../themes/blob.vue';
import bg2Svg from '../themes/blob-scene.vue';
import bg3Svg from '../themes/blob-scatter.vue';
import bg4Svg from '../themes/polygon-scatter.vue';
import bg5Svg from '../templates/wave.vue';
import Cover from '../slides/Cover.vue';
import MultipleChoice from '../slides/MultipleChoice.vue';
import MultipleChoiceQuiz from '../slides/MultipleChoiceQuiz.vue';
import MultiField from '../slides/MultiField.vue';
import LikertScale from '../slides/LikertScale.vue';
import Rating from '../slides/Rating.vue';
import TextArea from '../slides/TextArea.vue';
import TextField from '../slides/TextField.vue';
import BackCover from '../slides/BackCover.vue';
import Section from '../slides/Section.vue';

export default {
  mixins: [Slideshow],
  components: {
    bg0Svg,
    Cover,
    MultipleChoiceQuiz,
    MultipleChoice,
    MultiField,
    Rating,
    LikertScale,
    TextArea,
    TextField,
    BackCover
  },
  props: {
    primaryColor: {
      type: String,
      required: true
    },
    bg: {
      type: String,
      required: true
    },
    bgVersion: {
      type: Number,
      required: true
    },
    pages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    statusCode: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      nav: 'center',
      id2: '', 
      text: 'center',
      text1: 'center',
      slide: 'cover',
      itemId: '',
      selectedSvg: bg0Svg, // Default SVG bg,
      availableSlidePages: [
        { name: "cover", component: Cover },
        { name: "multi-field",  component: MultiField },
        { name: "multiple-choice", component: MultipleChoice },
        { name: "multiple-choice-quiz", component: MultipleChoiceQuiz },
        { name: "rating", component: Rating },
        { name: "likert-scale", component: LikertScale },
        { name: "text-area", component: TextArea },
        { name: "text-field", component: TextField },
        { name: "back-cover", component: BackCover },
        { name: "section", component: Section }
      ],
      currentSlidePageIndex: -1,
      currentPageSlide: {},
      currentSection: 0,
      texts: ['a buzz', 'a vibe', 'energy', 'joy'],
      animatedText: '',
      currentTextIndex: 0,
      currentIndex: 0,
      hasError: false,
      submitAnswer: false,
      isRankPage: false,
    };
  },
  created() {
    this.currentPageSlide = this.availableSlidePages[0].component; // default to cover slide
    // console.log('this.statusCode', this.statusCode)
  },
  mounted() {
    this.typeWriter();
  },
  computed: {
    ...mapGetters(['getSlide','isLoading']),
    ...mapState({
      // availableSlides: "slides",
      form: "form",
    }),
    showLandingPage() {
      return !this.$route.params.id;
    },
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    textColor() {
      return this.$store.state.textColor;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
    backgroundImageStyle() {
      return {
        background: '#f9fafb',
        // backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.0))',
        backgroundSize: 'cover', // Adjust as needed
        backgroundPosition: 'center', // Adjust as needed
        gradient: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)',
        aspectRatio: "5"
      };
    },
    progress() {
      if(this.currentSlidePageIndex>0) {
        return (this.currentSlidePageIndex + 1) / this.form.tableOfContents[this.currentSection].pages.length * 100;
      } else {
        return 0;
      }
    },
  },

  watch: {
    currentSlidePageIndex(val) {
      this.$store.dispatch('setSlidePayload', this.form.tableOfContents[this.currentSection].pages[val]);
      // const inputType = this.form.tableOfContents[0].pages[val].inputType;
      const inputType = val === 99 ? 'back-cover' : (this.form.tableOfContents[this.currentSection].pages[val] ? this.form.tableOfContents[this.currentSection].pages[val].inputType : 'cover');
      this.currentPageSlide = this.availableSlidePages.find(slide => slide.name === inputType).component;
    },
    currentSection(val) {
      if(this.currentSlidePageIndex < 0) { // prevents shows section page when going through previous slides
        // set current section in store
        this.$store.dispatch('setCurrentSection', val);
        // set currentPageSlide to section
        this.currentPageSlide = this.availableSlidePages.find(slide => slide.name === 'section').component;
      }
    },

    // currentSlideIndex: "updateUrl",
    // $route: "updateSlides",
    bg(v) {
        if (v) {
          this.changeSvg(v);
        }
      },
    primaryColor(v) {
      if (v) {
        this.changeColor(v);
      }
    },
    deep: true
  },
  methods: {
    nextPage() {
      if(this.currentSlidePageIndex === this.form.tableOfContents[this.currentSection].pages.length - 1) { // last slide  && currentPage !== pages-1
        if(this.currentSection === this.form.tableOfContents.length - 1) { // last section
          this.currentSlidePageIndex = 99; // back cover
        } else {
          this.currentSection++; // next section
          this.currentSlidePageIndex = -1; //might be risky to set to -1, but it works.
        }
      } else {
        this.currentSlidePageIndex++;
      }
    },
    beforeNextPage() {
      this.submitAnswer = true;
      var currentPage = this.form.tableOfContents[this.currentSection].pages[this.currentSlidePageIndex];
      // if input is required and empty, set error message else navigate to next page
      if(currentPage.responseIsRequired && !this.getSlide.answer) {
        this.hasError = true;
      } else {
        this.nextPage();
        this.hasError = false;
      }
    },
    prevPage() {
      if (this.currentSlidePageIndex > 0) {
        if (this.currentSlidePageIndex === 99) {
          this.currentSlidePageIndex = this.form.tableOfContents[this.currentSection].pages.length - 1;
        } else {
          this.currentSlidePageIndex--;
        }
      } 
      else if (this.currentSlidePageIndex === 0 && this.currentSection > 0) { // first slide of new section
          this.currentSection--; // previous section
          this.currentSlidePageIndex = this.form.tableOfContents[this.currentSection].pages.length - 1;
        }
      else {
        this.currentSlidePageIndex = -1;
        this.currentPageSlide = this.availableSlidePages[0].component; // default to cover slide
      }
    },
    previousSlide() {
      this.$emit("previous");
    },
    nextSlide() {
      this.$emit("next");
    },
    gotoExternalPage() {
      window.open('https://www.cheqqmate.com', '_blank');
    },
    getTransition: function(slide, type) {
      const defaultTransitions = {
        enter: "fadeIn",
        leave: "fadeOut",
        enterPrev: "fadeInLeft",
        enterNext: "fadeInRight",
        leavePrev: "fadeOutRight",
        leaveNext: "fadeOutLeft"
      };

      switch (type) {
        case "enter":
        case "leave":
          return slide[type] === undefined
            ? defaultTransitions[type]
            : slide[type] === null
            ? null
            : slide[type];
          break;

        default:
          if (slide.enter === undefined && slide.leave === undefined) {
            return slide[type] === undefined
              ? defaultTransitions[type]
              : slide[type] === null
              ? null
              : slide[type];
          } else {
            return null;
          }
          break;
      }
    },
    ...mapMutations({
      setCurrentIndex: "setCurrent"
    }),
    submit() {
      //use this.itemId to navigate to the `https://app.cheqqmate.com/${this.itemId}`https://app.cheqqmate.com/399u0l_fsA
      // this.$router.push({ name: 'cover', params: { id: this.itemId } });
      // I need the url to be like this: https://app.cheqqmate.com/399u0l_fsA
      window.location.href = `https://app.cheqqmate.com/${this.itemId}`;
    },
    typeWriter() {
      if (this.currentIndex < this.texts[this.currentTextIndex].length) {
        this.animatedText += this.texts[this.currentTextIndex].charAt(this.currentIndex);
        this.currentIndex++;
        setTimeout(this.typeWriter, 200); // adjust the typing speed here
      } else {
        // Move to the next text and start over after a delay
        setTimeout(() => {
          this.animatedText = '';
          this.currentIndex = 0;
          this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
          this.typeWriter();
        }, 2000); // adjust the delay here
      }
    },
    changeSvg(bgName) {
      switch (bgName) {
        case 'solid':
          this.selectedSvg = bg0Svg;
          break;
        case 'blob':
          this.selectedSvg = bg1Svg;
          break;
        case 'blob-scene':
          this.selectedSvg = bg2Svg;
          break;
        case 'blob-scatter':
          this.selectedSvg = bg3Svg;
          break;
        case 'polygon-scatter':
          this.selectedSvg = bg4Svg;
          break;
        case 'wave':
          this.selectedSvg = bg5Svg;
          break;
        default:
          this.selectedSvg = bg0Svg;
          break;
      }
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },
    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },
    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },
    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      const everDarkerShade = this.rgbToHex(
        this.adjustColor(r, -60),
        this.adjustColor(g, -60),
        this.adjustColor(b, -60)
      );

      return [lighterShade, darkerShade + '4D', everDarkerShade]; // 30% = 4D, 40% = 66, 50% = 80, 70% = B3
    },
    generateRandomMessages() {
      // console.log('randomMessages2', this.randomMessages2)
      // generate user list with 100 chats per turn:
      const chats = [];

      // generate chats with random message, time and user:
      for (let i = 0; i < 50; i++) {

        // get random message from message array:
        // const message = this.randomMessages[Math.floor((Math.random() * this.randomMessages.length)) | 0];
        const message = randomMessages[Math.floor((Math.random() * randomMessages.length)) | 0];

        // prepare chat object:
        chats[i] = {
          message  : message
        }
      }
        // store chats array in vuex store:
        this.$store.dispatch('setFormMessages', chats);
    }     
  }
};
</script>
<style lang="scss">
.background {
  width: 100vw;
  height: 100vh;
}
#app2 {
  width: 4% ;
  // height: 100vh;
}
.v-text-field {
    input {
      color: rgba(169, 169, 169, 0.33);
    }
  }
  .header-landing {
    background-image: url("../assets/landing-bg-1.svg") !important;
    background-size: cover !important;
  }
  .lower-right-quadrant {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1.75rem;
  }
  .text-input .v-label {
    font-size: 18px !important;
    color: inherit !important;
    opacity: 1 !important;
  }

  .text-input .v-text-field__slot input {
    font-size: 24px !important;
    color: inherit !important;
  }
  .text-animation {
    border-right: 0.1em solid;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 7s steps(40, end), blink-caret .75s step-end infinite;
  }
</style>