

<template>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
      <rect width="100%" height="100%" :fill="primaryColor"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: "Solid",
    props: {
      primaryColor: {
        type: String,
        default: "#f9fafb"
      },
      secondaryColor: {
        type: String,
        default: "#0b2239"
      },
      tertiaryColor: {
        type: String,
        default: "#113255"
      }
    }
  };
  </script>