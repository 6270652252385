import Vue from "vue";
import Router from "vue-router";

import store from "@/store";

//https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = store.state.slides.map(slide => {
  const { name, path } = slide;
  const component = () => import(`@/slides/${slide.name}.vue`);

  return {
    name,
    path,
    component,
    props: true
  };
});

Vue.use(Router);

export default new Router({
  // mode: "hash", // to use history mode, you need to read https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations the easiest here is to keep HASH for GitHub pages
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
