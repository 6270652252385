

<template>
  <svg v-if="version == 1" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <g transform="translate(78.06725510865067 -0.8752007141651177)">
      <path
        d="M269.6 -155.9C320.3 -67.7 312.4 54 257.7 144.6C203 235.2 101.5 294.6 -30.4 312.2C-162.4 329.8 -324.8 305.5 -403.4 201.1C-482.1 96.7 -477 -87.9 -395.8 -193.6C-314.7 -299.3 -157.3 -326.2 -24 -312.3C109.4 -298.5 218.8 -244 269.6 -155.9"
        :fill="secondaryColor"></path>
    </g>
  </svg>
  <svg v-else-if="version == 2" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <g transform="translate(1460.8072530406196 34.10052717147386)">
      <path
        d="M357.5 -214.3C419.4 -99.2 395.5 57.7 321.6 172.5C247.7 287.3 123.8 360.2 -19.3 371.3C-162.4 382.4 -324.8 331.8 -375.5 230.4C-426.2 128.9 -365.4 -23.4 -284.2 -149.6C-203 -275.9 -101.5 -375.9 23.2 -389.3C147.8 -402.7 295.6 -329.3 357.5 -214.3"
        :fill="secondaryColor"></path>
    </g>
  </svg>
  <svg v-else-if="version == 3" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <g transform="translate(0.5738809149093811 709.0336093105839)">
      <path
        d="M357.5 -214.3C419.4 -99.2 395.5 57.7 321.6 172.5C247.7 287.3 123.8 360.2 -19.3 371.3C-162.4 382.4 -324.8 331.8 -375.5 230.4C-426.2 128.9 -365.4 -23.4 -284.2 -149.6C-203 -275.9 -101.5 -375.9 23.2 -389.3C147.8 -402.7 295.6 -329.3 357.5 -214.3"
        :fill="secondaryColor"></path>
    </g>
  </svg>
  <svg v-else-if="version == 4" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <g transform="translate(737.2561956709899 346.32751471160816)">
      <path
        d="M321.3 -157.2C398.4 -52 430.4 107.5 369.4 201.3C308.3 295.2 154.2 323.4 -4.1 325.8C-162.4 328.1 -324.8 304.7 -394.5 205.8C-464.2 107 -441.2 -67.2 -360.1 -174.8C-278.9 -282.3 -139.4 -323.2 -8.7 -318.2C122.1 -313.2 244.2 -262.3 321.3 -157.2"
        :fill="secondaryColor"></path>
    </g>
  </svg>
  <svg v-else-if="version == 5" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <g transform="translate(1482.0499881168669 665.1127683689074)">
      <path
        d="M321.3 -157.2C398.4 -52 430.4 107.5 369.4 201.3C308.3 295.2 154.2 323.4 -4.1 325.8C-162.4 328.1 -324.8 304.7 -394.5 205.8C-464.2 107 -441.2 -67.2 -360.1 -174.8C-278.9 -282.3 -139.4 -323.2 -8.7 -318.2C122.1 -313.2 244.2 -262.3 321.3 -157.2"
        :fill="secondaryColor"></path>
    </g>
  </svg>
</template>
  
<script>
export default {
  name: "blob",
  props: {
    primaryColor: {
      type: String,
      default: "#0e2a47"
    },
    secondaryColor: {
      type: String,
      default: "#0b2239"
    },
    tertiaryColor: {
      type: String,
      default: "#113255"
    },
    version: {
      type: Number,
      default: 2
    }
  }
};
</script>
<style scoped>
.parent-container {
  width: 100%;
  height: 100vh;
  /* or any other height you need */
}

#visual {
  width: 100%;
  height: 100%;
}</style>