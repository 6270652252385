

<template>
  <svg v-if="version == 1" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.dev/svgjs" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
    <rect x="0" y="0" width="1440" height="560" :fill="primaryColor"></rect>
    <defs>
      <linearGradient id="grad1_0" x1="61.1%" y1="0%" x2="100%" y2="100%">
        <stop offset="-1.4285714285714342%" stop-color="#001220" stop-opacity="1"></stop>
        <stop offset="101.42857142857143%" stop-color="#001220" stop-opacity="1"></stop>
      </linearGradient>
    </defs>
    <defs>
      <linearGradient id="grad2_0" x1="0%" y1="0%" x2="38.9%" y2="100%">
        <stop offset="-1.4285714285714342%" stop-color="#001220" stop-opacity="1"></stop>
        <stop offset="101.42857142857143%" stop-color="#001220" stop-opacity="1"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(1440, 0)">
      <path
        d="M0 140C-14.3 136.8 -28.6 133.7 -41.7 128.4C-54.8 123.1 -66.8 115.7 -78.2 107.6C-89.5 99.5 -100.3 90.6 -110 79.9C-119.7 69.3 -128.3 56.9 -133.1 43.3C-138 29.6 -139 14.8 -140 0L0 0Z"
        :fill="secondaryColor"></path>
    </g>
    <g transform="translate(0, 560)">
      <path
        d="M0 -140C13.8 -135.8 27.5 -131.7 41.4 -127.4C55.3 -123.2 69.3 -118.9 80.5 -110.8C91.7 -102.8 100.2 -90.9 109.2 -79.4C118.3 -67.8 127.9 -56.4 133.1 -43.3C138.4 -30.1 139.2 -15 140 0L0 0Z"
        :fill="secondaryColor"></path>
    </g>
  </svg>
</template>
  
<script>
export default {
  name: "blob-scene",
  props: {
    primaryColor: {
      type: String,
      default: "#0e2a47"
    },
    secondaryColor: {
      type: String,
      default: "#0b2239"
    },
    tertiaryColor: {
      type: String,
      default: "#113255"
    },
    version: {
      type: Number,
      default: 2
    }
  }
};
</script>
<style scoped>.parent-container {
  width: 100%;
  height: 100vh;
  /* or any other height you need */
}

#visual {
  width: 100%;
  height: 100%;
}</style>