import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    embedded: false,
    loadingCounter: 0,
    currentIndex: 0,
    currentSectionIndex: 0,
    slidePayload: {},
    primaryColor: '',
    secondaryColor: 'f5f5f5',
    secondaryColorHex: '#f5f5f5',
    textColorHex: '#616161',
    textColor: '616161',
    buttonTextColor: '616161',
    buttonTextColorHex: '#616161',
    contrast: false,
    contactDetails: {},
    contactId: null,
    slides: [
      {
        name: "Cover",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 1
      },
      {
        name: "MultipleChoice",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 2
      },
      {
        name: "TextArea",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 3
      },
      {
        name: "Rating",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 4
      },
      {
        name: "TextField",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 5
      },
      {
        name: "MultiField",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 6
      },
      {
        name: "MultipleChoiceQuiz",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 7
      },
      {
        name: "LikerScale",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 8
      },    
      {
        name: "BackCover",
        path: "/:id",
        query: {},
        enterNext: "fadeIn",
        enterPrev: "fadeIn",
        leaveNext: "rotateOutDownLeft",
        leavePrev: "fadeOut",
        steps: 99
      },
    ],
    form: {},
    quizAnswers: [],
    errorMessage:[],
    templateId: 0,
    formMessages: [],
    formTemplates: [
        {
            "id": "1",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "CB997E",
                "secondary": "FFE8D6",
                "text": "283618",
                "buttonText": "6b705c",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "606c38-283618-fefae0-dda15e-bc6c25",
                "contrast": "true"
            },
            "name": "Member Vetting",
            "description": "A process to assess and evaluate potential members for suitability based on community guidelines, interests, and experience.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "How did you hear about this community?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Social Media",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Friend",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Other",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Have you been a part of any similar communities before?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What interests you the most about joining this community?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Do you agree to follow the community guidelines?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "2",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "DAD7CD",
                "secondary": "A3B18A",
                "text": "588157",
                "buttonText": "344E41",
                "theme": "bg1",
                "themeVersion": 1,
                "palette": "dad7cd-a3b18a-588157-3a5a40-344e41",
                "contrast": "true"
            },
            "name": "Member Onboarding",
            "description": "Introduces new members to the community, helping them get familiar with the platform, resources, and guidelines.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What is your primary goal for joining this community?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What are your areas of expertise?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you like to connect with a community mentor?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Do you agree to receive email updates about community activities?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 4,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "How familiar are you with our community platform?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        }
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "3",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "FEFAE0",
                "secondary": "DDA15E",
                "text": "BC6C25",
                "buttonText": "BC6C25",
                "theme": "bg1",
                "themeVersion": 2,
                "palette": "606c38-283618-fefae0-dda15e-bc6c25",
                "contrast": "true"
            },
            "name": "Event Feedback",
            "description": "Collects opinions and experiences from participants after an event to understand what worked well and where improvements can be made.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "'How satisfied were you with the event overall?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What was the most valuable part of the event?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you recommend this event to others?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Was the event length appropriate?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 4,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What topics would you like to see covered in future events?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        }
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "4",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "283618",
                "secondary": "606C38",
                "text": "FEFAE0",
                "buttonText": "FEFAE0",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "606c38-283618-fefae0-dda15e-bc6c25",
                "contrast": "true"
            },
            "name": "Course Feedback",
            "description": "Gathers insights from participants about the course content, delivery, and structure to assess its effectiveness and areas for improvement.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "How clear were the course objectives?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What did you find most useful in the course?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you be interested in taking advanced courses?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Did the course meet your expectations?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 4,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "How could the course be improved?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "5",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "F5EBE0",
                "secondary": "D5BDAF",
                "text": "616161",
                "buttonText": "EDEDE9",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "edede9-d6ccc2-f5ebe0-e3d5ca-d5bdaf",
                "contrast": "true"
            },
            "name": "Member Offboarding",
            "description": "A process for gathering feedback from members leaving the community, to understand why they are leaving and how the community can improve.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What was your reason for leaving the community?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "No longer relevant",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Time constraints",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Found a better fit",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Personal reasons",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Other",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What did you enjoy most about being a part of this community?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you consider rejoining the community in the future?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Do you have any feedback on how we can improve the community?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 4,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "Did you feel supported during your time in the community?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        }
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "6",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "F2E8CF",
                "secondary": "386641",
                "text": "6A994E",
                "buttonText": "A7C957",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "386641-6a994e-a7c957-f2e8cf-bc4749",
                "contrast": "true"
            },
            "name": "Community Engagement & Activity Feedback",
            "description": "Monitors how engaged members are, what activities they participate in, and how the community can encourage more involvement.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "How often do you engage with the community?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Daily",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Weekly",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Monthly",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Rarely",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Never",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What type of content do you find most engaging?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Discussion posts",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Events",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Courses",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Surveys",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Other",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "How would you rate the overall engagement of the community?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What features would you like to see added to the community platform?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "7",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "A6C48A",
                "secondary": "FFFFFF",
                "text": "FFFFFF",
                "buttonText": "678D58",
                "theme": "bg1",
                "themeVersion": 5,
                "palette": "74d3ae-678d58-a6c48a-f6e7cb-dd9787",
                "contrast": "true"
            },
            "name": "Content Suggestions",
            "description": "Encourages members to provide ideas and feedback for new content, allowing them to contribute to the community’s resource pool.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What topics would you like to learn more about?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What type of content do you prefer?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Videos",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Articles",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Webinars",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Interactive challenges",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Other",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "How often would you like new content to be shared?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Daily",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Weekly",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Monthly",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Occasionally",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you be willing to contribute content to the community?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        }
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "8",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "74D3AE",
                "secondary": "283618",
                "text": "283618",
                "buttonText": "678D58",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "74d3ae-678d58-a6c48a-f6e7cb-dd9787",
                "contrast": "true"
            },
            "name": "Technical Support or Troubleshooting Requests",
            "description": "A process for members to report technical issues or troubleshooting needs, ensuring smooth platform operation and member support.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What issue are you experiencing?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "How urgent is this issue?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Critical",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "High",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Medium",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "Low",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Have you tried any troubleshooting steps?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 4,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please provide any additional details that might help us resolve the issue.",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        },
        {
            "id": "9",
            "imageUrl": "",
            "logoUrl": "",
            "design": {
                "primary": "CCC5B9",
                "secondary": "252422",
                "text": "403D39",
                "buttonText": "CCC5B9",
                "theme": "bg1",
                "themeVersion": 3,
                "palette": "fffcf2-ccc5b9-403d39-252422-eb5e28",
                "contrast": "true"
            },
            "name": "Polls and Surveys for Idea Validation",
            "description": "Collects member opinions on potential ideas or projects, helping to determine interest and feasibility before proceeding.",
            "tableOfContents": [
                {
                    "id": 0,
                    "level": "Section",
                    "description": "Introduction",
                    "pages": [
                        {
                            "id": -1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Please let us know who you are.",
                            "inputType": "multi-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 0,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "How interested are you in the idea of [insert idea]?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 1,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "What challenges do you foresee with [insert idea]?",
                            "inputType": "text-field",
                            "options": [
                                {
                                    "name": "",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 2,
                            "isHidden": false,
                            "level": "Page",
                            "section": "0",
                            "question": "Would you be willing to participate in a pilot program for this idea?",
                            "inputType": "multiple-choice",
                            "options": [
                                {
                                    "name": "Yes",
                                    "explanation": "",
                                    "nextSectionId": null
                                },
                                {
                                    "name": "No",
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        },
                        {
                            "id": 3,
                            "isHidden": false,
                            "level": "Page",
                            "section": "1",
                            "question": "How likely are you to recommend [insert idea] to others?",
                            "inputType": "rating",
                            "options": [
                                {
                                    "name": null,
                                    "explanation": "",
                                    "nextSectionId": null
                                }
                            ],
                            "response": "",
                            "explanation": "",
                            "placeholder": "Enter your answer here"
                        }
                    ]
                }
            ],
            "closeDate": "2024-12-14",
            "closeTime": "12:00",
            "isPublished": false,
            "captureContacts": true,
            "redirectUrl": "www.cheqqmate.com",
            "status": "draft",
        }
    ],
  },
  actions: {
    setSlidePayload({state, commit}, payload){
      commit('SET_SLIDE_PAYLOAD', payload)
    },
    setCurrentSection({state, commit}, index){
        commit('SET_CURRENT_SECTION', index)
    },
    setFormMessages({state, commit}, messages){
        commit('SET_FORM_MESSAGES', messages)
    },
  },
  getters: {
    isLoading: state => {
        return state.loadingCounter > 0;
      },
    getSlide: state => state.slidePayload,
    getFormTemplates : state => state.formTemplates,
    getSectionIndex: state => state.currentSectionIndex,
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    setCurrent(state, index) {
      state.currentIndex = index;
    },
    SET_SLIDE_PAYLOAD(state, payload) {
      state.slidePayload = payload;
    },
    ADD_ANSWER_TO_SLIDE(state, answer) {
        state.slidePayload.answer = answer;
    },
    SET_ERROR_MESSAGES(state, messages) {
        state.errorMessages = messages;
    },
    SET_CURRENT_SECTION(state, index) {
        state.currentSectionIndex = index;
    },
    SET_PRIMARY_COLOR(state, color) {
      state.primaryColor = color;
      state.primaryColorHex = '#' + color;
    },
    SET_SECONDARY_COLOR(state, color) {
      state.secondaryColor = color;
      state.secondaryColorHex = '#' + color;
    },
    SET_TEXT_COLOR(state, color) {
      state.textColor = color;
      state.textColorHex = '#' + color;
    },
    SET_BTN_TEXT_COLOR(state, color) {
      state.buttonTextColor = color;
      state.buttonTextColorHex =  '#' + color;
    },
    SET_FORM_DETAILS(state, form) {
      state.form = form;
    },
    SET_FORM_MESSAGES(state, messages) {
        state.formMessages = messages;
    },
    ADD_QUIZ_ANSWER(state, answer) {
        //splice to remove the answer if it already exists based on the question id
        state.quizAnswers.splice(answer.QuestionId, 1, answer);
    },
    SET_TEMPLATE_ID(state, id) {
        state.templateId = id;
        // set form details
        state.form = state.formTemplates.filter(form => form.id === Number(state.templateId))[0];
    },
    SET_CONTRAST(state, contrast) {
        if(contrast === 'true') {
          state.contrast = true;
        } else {
          state.contrast = false;
      }
    },
    START_LOADING: state => {
        state.loadingCounter++;
    },
    FINISH_LOADING: state => {
        state.loadingCounter--;
    },
    SET_CONTACT_DETAILS: (state, contactDetails) => {
      state.contactDetails = contactDetails;
    },
    SET_CONTACT_ID: (state, contactId) => {
      state.contactId = contactId;
    },
    SET_EMBEDDED: (state, embedded) => {
        state.embedded = embedded;
    },
  }
  /* eslint-enable no-param-reassign */
});
