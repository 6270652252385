<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-row
    v-col(:style="{color: textColorHex, fontSize: '24px' }")
      v-row
      div.d-flex.align-start.text-wrap
        p(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.id+1 }}
        v-icon.mt-1.mx-n2(large :color="textColorHex")(left) mdi-menu-right
        p.mx-2(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}
      v-item-group.d-flex.align-start.text-wrap
        v-col(v-for="(idx) in 5" 
            :key="idx" 
            class="shrink ml-n2"
            cols="12"
            md="1"
          )
          v-item(v-slot="{ active, toggle }")
            v-card(
                class="d-flex align-center"
                :color="active || getAnswer === idx ? secondaryColorHex : getColorVariants[1]"
                outlined
                elevation="0"
                height="50"
                @click="setAnswer(idx), toggle()"
              )
                h4(:style="{color: active || getAnswer === idx ? buttonTextColorHex : textColorHex}" :class="isMobile() ? 'ml-4' : 'ml-6'") {{ idx }}
                v-row
                      v-icon(small class="mt-14 mb-n6 ml-n2 ") {{ idx === 1 ? 'mdi-emoticon-sad-outline' : idx === 5 ? 'mdi-emoticon-happy-outline' : '' }}
      v-col(
        class="mt-2 shrink ml-n2"
        cols="12"
        lg="3"
        md="4"
        sm="7"
      ) 
        v-alert(
          v-if="getErrors[0]"
          class="mb-2 shake"
          dense
          outlined
          type="error") {{getErrors[0]}}
        v-btn(v-else :color="secondaryColorHex" light raised elevation="0" class="font-weight-regular text-none" @click="submit(getSlide.responseIsRequired)")
          p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Submit
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from 'axios';

  const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

  export default {
    name: "LikertScale",
    props: {
      hasError: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        id2: '',
        slide: 'multiple-choice',
        optionIcons: ["mdi-alpha-a-box", "mdi-alpha-b-box", "mdi-alpha-c-box", "mdi-alpha-d-box", "mdi-alpha-e-box"],
        errorMessages: [],
        answer: '',
        responseUpdated: false,
      };
    },
    mounted() {
      if (!this.getSlide.answer) {
        this.$store.commit("ADD_ANSWER_TO_SLIDE", null);
      }
    },
    watch: {
      hasError(e) {
        if (e) {
          this.errorMessages = ['Please select an option.'];
        }
      },
    },
    computed: {
      ...mapGetters(["getSlide"]),
      embedded() {
        return this.$store.state.embedded;
      },
      getErrors() {
        return this.errorMessages;
      },
      getAnswer: {
        get() {
          return this.answer || this.getSlide.answer;
        },
        set(value) {
          this.getSlide.answer = value;
        }
      },
      textColorHex() {
        return this.$store.state.textColorHex;
      },
      buttonTextColorHex() {
        return this.$store.state.buttonTextColorHex;
      },
      secondaryColorHex() {
        return this.$store.state.secondaryColorHex;
      },
      contactId() {
        return this.$store.state.contactId;
      },
      primaryColor() {
        return this.$store.state.primaryColor;
      },
      secondaryColor() {
        return this.$store.state.secondaryColor;
      },
      getColorVariants() {
        return this.getLighterAndDarkerShades('#'+ this.primaryColor) || [];
      },
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
          return true;
        }

        return false;
      },
      setAnswer(answer) {
        this.answer = answer;
        this.$store.commit("ADD_ANSWER_TO_SLIDE", answer);
        this.errorMessages = [];

        // if user has already submitted, but updates the answer, update the responseUpdate 
        if (this.getSlide.response) {
          this.responseUpdated = true;
        }
      },
      submit(isRequired) {
      if(isRequired){
        if (this.answer || this.getSlide.response) {
          // Form is valid, proceed with submission
          this.registerFormTransaction();
          this.errorMessages = []; // Clear error messages
        } 
        else {
          // Form is invalid, show validation errors
          this.errorMessages = ['This field is required.'];
        }    
      } else {
        // Form is not required, proceed with submission
        this.registerFormTransaction();
        this.errorMessages = []; // Clear error messages 
      }      
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerFormTransaction() {
        // if getSlide.response do nothing
        if (this.getSlide.response && !this.responseUpdated) {
          this.nextSlide();
          return;
        }
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "FormId": this.$store.state.form.id,
              "ContactId": this.responseUpdated ? this.getSlide.response.ContactId : this.contactId?this.contactId:-1,
              "QuestionId": this.getSlide.id,
              "Answer": this.getSlide.answer,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/formTransaction`,postData).then(() => {
          this.getSlide.response = postData,
          this.nextSlide();
        })
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },
    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },
    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },
    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 60),
        this.adjustColor(g, 60),
        this.adjustColor(b, 60)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      const everDarkerShade = this.rgbToHex(
        this.adjustColor(r, -90),
        this.adjustColor(g, -90),
        this.adjustColor(b, -90)
      );

      return [lighterShade, darkerShade + '80', everDarkerShade]; // 30% = 4D, 40% = 66, 50% = 80, 70% = B3
    },
    },
  };
  </script>
  
  <style scoped>
  .slide-FirstPage {
    background-color: black;
  }
  .question-number {
    color: #D53E59; /* pink darken-1 */
    font-size: 18px;
  }

  .button {
    /* color: white; */
    font-size: 16px;
    padding: 10px 20px;
  }

  .shake {
    animation: shake 0.25s;
  }
</style>