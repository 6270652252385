<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  //- p getSlide.answer: {{ answer }}
  v-row
    v-col(:style="{color: textColorHex, fontSize: '24px' }")
      v-row
      div.d-flex.align-start.text-wrap
        //- question
        p(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.id+1 }}
        v-icon.mt-1.mx-n2(large :color="textColorHex")(left) mdi-menu-right
        p.mx-2(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}
      //-using updateAnswer as I want user to press submit before displaying the answer
      v-item-group(v-if="!updateAnswer")
        v-virtual-scroll(
          height="300"
          width="70%"
          item-height="70"
          :items="getSlide.options"
          v-slot="{ item, index }"
        )
          v-item(v-slot="{ active, toggle }")
            v-card(
              class="ma-4 d-flex align-center"
              :color="active || getAnswer === item.name ? secondaryColorHex : getColorVariants[1]"
              outlined
              elevation="0"
              height="50"
              @click="setAnswer(item), toggle()"
            )
              v-icon(
                :style="{ color: active || getAnswer === item.name ? buttonTextColorHex : textColorHex, fontSize: '24px' }"
                class="ml-2"
              ) {{ optionIcons[index] }}
              span(
                :style="{ color: active || getAnswer === item.name ? buttonTextColorHex : textColorHex, fontSize: '24px' }"
                class="ml-2 text-body-1"
              ) {{ item.name }}
      v-col(v-else)
        v-chip-group(column class="mt-n4")
          v-chip(
            label
            outlined
            :color="textColorHex"
          ) 
            v-icon(:style="{color: textColorHex, fontSize: '24px' }") {{ optionIcons[getSlide.options.findIndex(option => option.name === getOption[0]?.name)] }}
            p(:style="{color: textColorHex, fontSize: '24px' }" class="ml-2 mr-2 mt-4 text-body-1") {{ getOption[0]?.name }}
            LottieAnimation(v-if="getOption[0]?.isCorrect ===true" path='./assets/animations/correct.json' 
              :width="30" 
              :height="30"
              :loop="false")
            LottieAnimation(v-else path='./assets/animations/incorrect.json' 
            :width="30" 
            :height="30"
            :speed="1.5"
            :loop="false")
        p(:style="{color: textColorHex, fontSize: '24px' }" class="delay mr-2 mt-4 text-body-1") {{ getOption[0]?.explanation }}      
      //- error message
      v-col(
        class="shrink ml-n2"
        cols="12"
        lg="3"
        md="4"
        sm="7"
      ) 
        v-alert(
          v-if="getErrors[0]"
          class="mb-2 shake"
          dense
          outlined
          type="error") {{getErrors[0]}}
        //- submit button, submit if answer is selected. next if animation is displayed
        v-btn(v-else :color="secondaryColorHex" light raised elevation="0" class="font-weight-regular text-none" @click="submit(getSlide.responseIsRequired, updateAnswer ? 'Next' : 'Submit' )")
          p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") {{ updateAnswer ? 'Next' : 'Submit' }}
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import axios from 'axios';
  import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
  const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

  export default {
    name: "MultipleChoiceQuiz",
    props: {
      hasError: {
        type: Boolean,
        default: false
      }
    },
    components: {
      LottieAnimation
    },
    data() {
      return {
        updateAnswer: '',
        steps: 4,
        id2: '',
        slide: 'multiple-choice-quiz',
        optionIcons: ["mdi-alpha-a-box", "mdi-alpha-b-box", "mdi-alpha-c-box", "mdi-alpha-d-box", "mdi-alpha-e-box"],
        isActive: false,
        answer2: '',
        errorMessages: []
      };
    },
    watch: {
      answer2: {
        handler() {
          this.isActive = !this.isActive;
        },
        deep: true
      },
      hasError(e) {
        if (e) {
          this.errorMessages = ['Please select an option.'];
        }
      },
      "getSlide.answer": {
        handler() {
          this.errorMessages = [];
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(["getSlide"]),
      getErrors() {
        return this.errorMessages;
      },
      getAnswer: {
        get() {
          return this.answer2 || this.getSlide.answer;
        },
        set(value) {
          this.getSlide.answer = value;
        }
      },
      embedded() {
        return this.$store.state.embedded;
      },
      textColorHex() {
        return this.$store.state.textColorHex;
      },
      buttonTextColorHex() {
        return this.$store.state.buttonTextColorHex;
      },
      secondaryColorHex() {
        return this.$store.state.secondaryColorHex;
      },
      contactId() {
        return this.$store.state.contactId;
      },
      answer() {
        return this.getSlide.answer || this.updateAnswer;
      },
      getOption() {
        return this.getSlide.options.filter(option => option.name === (this.getSlide.answer || this.updateAnswer));
      },
      primaryColor() {
        return this.$store.state.primaryColor;
      },
      secondaryColor() {
        return this.$store.state.secondaryColor;
      },
      getColorVariants() {
        return this.getLighterAndDarkerShades('#'+ this.primaryColor) || [];
      },     
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
          return true;
        }

        return false;
      },
    submit(isRequired, action) {
      if(action === 'Next'){
        // clear the answer
        this.updateAnswer = '';
        // Proceed to the next slide
        this.nextSlide();
        return;
      }
      if(isRequired){
        if (this.getSlide.answer) {
          // Form is valid, proceed with submission
          this.updateAnswer = this.getSlide.answer;
          this.registerQuizTransaction();
          this.errorMessages = []; // Clear error messages
        } 
        else {
          // Form is invalid, show validation errors
          this.errorMessages = ['This field is required.'];
        }    
      } else {
        // Form is not required, proceed with submission
        this.updateAnswer = this.getSlide.answer;
        this.registerQuizTransaction();
        this.errorMessages = []; // Clear error messages 
      }     
    },
    setAnswer(answer) {
      this.getSlide.answer = answer.name;
      var _answer = {};
      _answer.QuestionId = this.getSlide.id;
      _answer.answer = answer.name;
      _answer.isCorrect = answer.isCorrect;
      // push the answer to the store
      // answer.QuestionId = this.getSlide.id;
      this.$store.commit("ADD_QUIZ_ANSWER", _answer);

      // set the answer locally
      this.answer2 = answer;
      this.$store.commit("ADD_ANSWER_TO_SLIDE", answer.name);
      this.errorMessages = [];
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerQuizTransaction() {
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "QuizId": this.$store.state.form.id,
              "ContactId": this.contactId?this.contactId:-1,
              "QuestionId": this.getSlide.id,
              "Answer": this.getSlide.answer,
              "Score": this.getOption[0]?.isCorrect === true ? 1 : 0,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/quizTransaction`,postData).then(() => {
          this.getSlide.response = postData;
        })
    },
    selectAnswer(answer) {
      this.answer2 = answer;
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },
    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },
    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },
    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 60),
        this.adjustColor(g, 60),
        this.adjustColor(b, 60)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      const everDarkerShade = this.rgbToHex(
        this.adjustColor(r, -90),
        this.adjustColor(g, -90),
        this.adjustColor(b, -90)
      );

      return [lighterShade, darkerShade + '80', everDarkerShade]; // 30% = 4D, 40% = 66, 50% = 80, 70% = B3
    },
    },
  };
  </script>
  
  <style scoped>
  .slide-FirstPage {
    background-color: black;
  }
  .question-number {
    color: #D53E59; /* pink darken-1 */
    font-size: 18px;
  }

  .button {
    /* color: white; */
    font-size: 16px;
    padding: 10px 20px;
  }
  .delay {
    animation: fadeIn 1s;
  }
</style>