

<template>
  <svg id="visual" viewBox="0 0 1440 560" width="1440" height="560" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
    <rect width="1440" height="560" fill="#931F1F"></rect>
    <g>
      <g transform="translate(522 519)">
        <path
          d="M122.6 -34.6C138.9 10.4 118.4 72.6 78.2 100C38.1 127.3 -21.8 119.9 -66.6 88.2C-111.3 56.5 -141 0.5 -127.4 -40.8C-113.8 -82.1 -56.9 -108.7 -1.9 -108.1C53.1 -107.5 106.3 -79.6 122.6 -34.6Z"
          fill="none" stroke="#F7760E" stroke-width="20"></path>
      </g>
      <g transform="translate(1179 449)">
        <path
          d="M80.7 -28.7C91.6 7.3 78.5 48.7 49 71C19.4 93.3 -26.5 96.5 -58.6 74.7C-90.7 52.8 -108.9 5.7 -96.8 -31.9C-84.7 -69.5 -42.3 -97.7 -3.7 -96.5C34.9 -95.3 69.8 -64.7 80.7 -28.7Z"
          fill="none" stroke="#F7760E" stroke-width="20"></path>
      </g>
      <g transform="translate(53 404)">
        <path
          d="M56.1 -15.1C64.8 8.6 58.5 40.3 38.6 55.3C18.6 70.3 -15.1 68.7 -37.6 52.3C-60.2 35.8 -71.5 4.4 -63.4 -18.5C-55.3 -41.3 -27.6 -55.8 -2 -55.1C23.7 -54.5 47.4 -38.8 56.1 -15.1Z"
          fill="none" stroke="#F7760E" stroke-width="20"></path>
      </g>
    </g>
  </svg>
</template>
  
<script>
export default {
  name: "Meteor",
  props: {
    primaryColor: {
      type: String,
      default: "#0e2a47"
    },
    secondaryColor: {
      type: String,
      default: "#0b2239"
    },
    tertiaryColor: {
      type: String,
      default: "#113255"
    }
  },
  // created() {
  //   console.log('secondaryColor', this.secondaryColor);
  //   console.log('secondaryColor00', this.secondaryColor + 0o0 + 0o0);
  //   this.secondaryColor + 0o0
  // },
};
</script>