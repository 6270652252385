<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-row
    v-col(:style="{color: textColorHex, fontSize: '24px' }")
      v-row
      div.d-flex.align-start.text-wrap
        p(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.id+1 }}
        v-icon.mt-1.mx-n2(large :color="textColorHex")(left) mdi-menu-right
        p.mx-2(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}
      v-form(ref="form" v-model="valid")
        v-text-field(
          v-model="getSlide.answer" 
          :label="getSlide.placeholder" 
          single-line 
          :color="secondaryColorHex" 
          outlined 
          :backgroundColor="hexToRgba(secondaryColorHex)" 
          :style="labelStyle" 
          v-bind:input-style="inputStyle" 
          class="text-input"
          :rules="getSlide.responseIsRequired ? [rules.required]: []"
          :error-messages="errorMessages"
        )
        v-btn(:color="secondaryColorHex" raised elevation="0" class=" font-weight-regular text-none" @click="submit(getSlide.responseIsRequired)") 
          p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Submit
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
//:disabled="getSlide.responseIsRequired ? !getSlide.answer: false"
const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

export default {
  name: "TextField",
  props: {
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slide: 'text-field',
      valid: false,
      rules: {
          required: value => !!value || 'Required.'
      },
      errorMessages: [],
      responseUpdated: false,
    };
  },
  watch: {
    hasError(e) {
      if (e) {
        this.errorMessages = ['This field is required.'];
      }
    },
    "getSlide.answer": {
      handler() {
        this.errorMessages = [];
        // Check if  user has already submitted answer, if so, set the responseUpdated to true
        if (this.getSlide.response) {
          this.responseUpdated = true;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getSlide"]),
    embedded() {
      return this.$store.state.embedded;
    }, 
    textColorHex() {
      return this.$store.state.textColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    contrast(){
      return this.$store.state.contrast;
    },
    contactDetails() {
      return this.$store.state.contactDetails || {};
    },
    contactId() {
      return this.$store.state.contactId;
    },
    labelStyle() {
      return {
        color: this.dynamicLabelColor,
        opacity: 1
      };
    },
    inputStyle() {
      return {
        color: this.dynamicInputColor
      };
    },
    dynamicLabelColor() {
      return this.secondaryColorHex;
    },
    dynamicInputColor() {
      return this.secondaryColorHex;
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
        return true;
      }

      return false;
    },
    submit(isRequired) {
      if(isRequired){
        if (this.$refs.form.validate()) {
          // Form is valid, proceed with submission
          this.registerFormTransaction();
          this.errorMessages = []; // Clear error messages
        } 
        else {
          // Form is invalid, show validation errors
          this.errorMessages = ['This field is required.'];
        }    
      } else {
        // Form is not required, proceed with submission
        this.registerFormTransaction();
        this.errorMessages = []; // Clear error messages 
      }      
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    emitError() {
      this.$emit("has-error");
    },
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerFormTransaction() {
      // if getSlide.response do nothing
      if (this.getSlide.response && !this.responseUpdated) {
          this.nextSlide();
          return;
      }
      // Get the current date and time
      const currentDateTime = new Date();

      // Format the date using sqlConvertDate method
      const formattedDate = this.sqlConvertDate(currentDateTime);

      var postData = {
            "FormId": this.$store.state.form.id,
            "ContactId": this.responseUpdated ? this.getSlide.response.ContactId : this.contactId?this.contactId:-1,
            "QuestionId": this.getSlide.id,
            "Answer": this.getSlide.answer,
            "TransactionDate": formattedDate, // Use the formatted date
          }
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
      axios.defaults.headers.common['Content-Type'] = 'application/json';
      axios.post(`${cheqqMateDNABaseUrl}/formTransaction`,postData).then(() => {
        this.getSlide.response = postData,
        this.nextSlide();
      })
    },
    hexToRgba(hex) {
      if (!hex) return null;
      if (hex.length === 4) {
        var extendedHex = '#';
        for (var i = 1; i < hex.length; i++) {
          extendedHex += hex[i] + hex[i];
        }
        hex = extendedHex;
      }
      var bigint = parseInt(hex.replace('#', ''), 16);
      var r = (bigint >> 16) & 255;
      var g = (bigint >> 8) & 255;
      var b = bigint & 255;
      var a = 0.2;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
  }
};
</script>
<style >
.container {
  margin: 2rem;
}
.text-input .v-label {
  font-size: 18px !important;
  color: inherit !important;
  opacity: 1 !important;
}

.text-input .v-text-field__slot input {
  font-size: 18px !important;
  color: inherit !important;
}
</style>