<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '1rem' }")
  div(v-if='isLoading')
    v-container(:style="{ margin: isMobile() ? '1rem' : '1rem' }")
      v-row(class="fill-height" align-content="center" justify="center")
        //- mobile view
        v-col(v-if="isMobile()" class="text-start" cols="12" )
          v-chip(large label color="transparent" class="ma-3")
            p(class="grey--text text--darken-3 text-body-1 text-none mt-2 font-weight-regular") Powered by 
              span(class="grey--text text--darken-3 text-none font-weight-bold") CheqqMate
              v-progress-linear(class="mt-1" color="grey darken-3" indeterminate rounded height="4")
        //- desktop view
        v-col(v-else class="text-center" cols="4")
          v-chip(large label color="transparent" class="ma-3")
            p(class="grey--text text--darken-3 text-body-1 text-none mt-2 font-weight-regular") Powered by 
              span(class="grey--text text--darken-3 text-none font-weight-bold") CheqqMate
              v-progress-linear(class="mt-1" color="grey darken-3" indeterminate rounded height="4")
  //- version 1
  div(v-else-if='form.imageUrl')
    v-row(:class = "[isMobile() ? 'd-flex flex-column align-center justify-center': '' ]" )
      v-col(cols="6")
          div(style="justify-content: center; align-items: center; display: flex;" )
            h4(:class = "['text-none mt-2 font-weight-bold', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex }") {{ form.name }}
              h6(:class ="['text-none font-weight-regular', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex}") {{ form.description }}
              v-btn(v-if="form" elevation="0" class="mt-4" large @click="nextSlide" value="center" :color="secondaryColorHex")
                span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Get Started
      v-col(cols="6")
        div(v-if='form.imageUrl')
            v-img(
              max-height="300px"
              :src="form.imageUrl" 
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              class="grey darken-1"
              style="border-radius: 8px; opacity: 0.9;"
            )
        div(v-else)
          v-sheet(
            elevation="0"
            class="pa-2"
            color="grey lighten-5"
            rounded="lg"
            :style="{opacity: 0.4}"
          )
            v-avatar(size="200"  class="mx-auto" style="justify-content: center; align-items: center; display: flex;")
              v-icon(:size="125" :color="primaryColor" :style="{opacity: 0.2}") mdi-image-area
  div(v-else-if='!form.imageUrl')
    v-row(class="d-flex flex-column align-center justify-center")
      v-col(cols="12")
        div(style="justify-content: center; align-items: center; display: flex;" )
          h4(class="text-none mt-2 font-weight-bold text-center" :style="{color: textColorHex }") {{ form.name }}
            h6(class="text-none font-weight-regular text-center" :style="{color: textColorHex}") {{ form.description }}
            //- form.id < 99 is for templates (upto 98 of them)
            v-btn(v-if="showButton || form.id !== 99" elevation="0" class="mt-4" large @click="nextSlide" value="center" :color="secondaryColorHex")
              span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Get Started
    //- display leaderboard query https://app.cheqqmate/_1wL_D2WTG?rank=true
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Cover",
  // props: {
  //   id: {
  //     type: String,
  //     required: true
  //   },
  // },
  components: {
  },
  data() {
    return {
      id2: '', 
      primaryColor: '#154360', // Default color
      bg: 'bg1',
      text: 'center',
      text1: 'center',
      slide: 'cover',
      showButton: false
    };
  },
  computed: {
    ...mapGetters(['getSlide','isLoading']),
    embedded() {
      return this.$store.state.embedded;
    },
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    form() {
      return this.$store.state.form;
    },
    textColorHex() {
      return this.$store.state.textColorHex;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
  },
  watch: {
    form: {
      handler: function (newVal) {
        setTimeout(() => {
          this.showButton = true;
        }, 50);
      },
      deep: true,
    },
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
        return true;
      }

      return false;
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    submit() {
      alert("submit");
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },

    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },

    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },

    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      return [lighterShade, darkerShade];
    }
  },
};
</script>

<style scoped>
body {
  width: 100%;
  margin: 0;
}
html {
  height: auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
.title {
    display: flex;
    text-align: right;
    border-radius: 8px;
}
.upload-area {
    height: 400px;
    width: 100%;
    /* display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 8px; */
  }
  .container {
    margin: 2rem;
  }
</style>