<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
    v-row(v-if="lastPage.inputType !=='multiple-choice-quiz'" :class = "[isMobile() ? 'd-flex flex-column align-center justify-center': '' ]" )
      v-col(cols="12")
        div(v-if='form.id !== 9' style="justify-content: center; align-items: center; display: flex;" )
          h4(:class = "['text-none mt-2 font-weight-bold', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex }")  Thank you for your interest!
            h6(:class ="['text-none font-weight-regular', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex}") We'll be in touch with more information soon.
      v-row(:class = "[isMobile() ? 'd-flex flex-column align-center justify-center': '' ]" )  
        v-col(cols="12" style="justify-content: center; align-items: center; display: flex;")
          v-btn(elevation="0" class="mt-4" large @click="gotoExternalPage" value="center" :color="secondaryColorHex")
              span(:style="{color: buttonTextColorHex }" class="text-h5 text-none font-weight-medium") Visit our website
            
    v-row(v-else :class = "['d-flex flex-column align-center justify-center text-center relative-position']" style="justify-content: end; align-items: end; display: flex;")
      v-row( class='d-flex flex-column align-center justify-center text-center' style="justify-content: end; align-items: end; display: flex;")  
        v-col(cols="12")
          div(class="absolute-position" v-if='form.id !== 9' style="justify-content: center; align-items: center; display: flex;")
            h4(:class = "['text-none font-weight-bold', isMobile() ? 'text-end': 'text-start' ]" :style="{color: textColorHex }")  The results are in!
              h6(:class ="['text-none font-weight-regular', isMobile() ? 'text-center': 'text-start' ]" :style="{color: textColorHex}") {{ QuizResult.displayText }}          
              v-btn(elevation="0" class="" large @click="gotoExternalPage" value="center" :color="secondaryColorHex")
                span(:style="{color: buttonTextColorHex }" class="text-h5 text-none text-centre font-weight-medium") Visit our website
                  v-row( class='d-flex flex-column align-center justify-center text-center')  
                    v-col()
          div(class="absolute-animation" style="justify-content: center; align-items: center; display: flex;")
            LottieAnimation(v-if="QuizResult.percentage === 100" path='./assets/animations/celebrate.json' 
            :width="800" 
            :height="800"
            :loop="false")
</template>

<script>
import bg1Svg from '../templates/sprinkle.vue';
import bg2Svg from '../templates/simple-shiny.vue';
import bg3Svg from '../templates/meteor.vue';
import bg4Svg from '../templates/animated-shape.vue';
import bg5Svg from '../templates/wave.vue';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

import { mapGetters } from "vuex";
export default {
  name: "Cover",
  components: {
    bg1Svg,
    bg2Svg,
    bg3Svg,
    bg4Svg,
    bg5Svg,
    LottieAnimation
  },
  data() {
    return {
      id2: '', 
      primaryColor: '#154360', // Default color
      bg: 'bg1',
      text: 'center',
      text1: 'center',
      slide: 'cover',
      selectedSvg: bg1Svg, // Default SVG bg
    };
  },
  computed: {
    ...mapGetters(['getSlide','isLoading']),
    embedded() {
      return this.$store.state.embedded;
    },
    getColorVariants() {
      return this.getLighterAndDarkerShades(this.primaryColor) || [];
    },
    form() {
      return this.$store.state.form;
    },
    lastPage() {
      var form = this.form;
      return form.tableOfContents[0].pages[form.tableOfContents[0].pages.length - 1];
    },
    textColorHex() {
      return this.$store.state.textColorHex;
    },
    secondaryColorHex() {
      return this.$store.state.secondaryColorHex;
    },
    buttonTextColorHex() {
      return this.$store.state.buttonTextColorHex;
    },
    QuizResult() {
      var results = this.$store.state.quizAnswers;
      var totalQuestions = results.length;
      var correctAnswers = results.filter(result => result.isCorrect).length;
      var resultsObject = {
        totalQuestions: totalQuestions,
        correctAnswers: correctAnswers,
        displayText: `You answered ${correctAnswers} out of ${totalQuestions} questions correctly.`,
        percentage: (correctAnswers / totalQuestions) * 100,
      };
      return resultsObject;
    },
  },

  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.embedded) {
        return true;
      }

      return false;
    },
    changeSvg(bgName) {
      switch (bgName) {
        case 'bg1':
          this.selectedSvg = bg1Svg;
          break;
        case 'bg2':
          this.selectedSvg = bg2Svg;
          break;
        case 'bg3':
          this.selectedSvg = bg3Svg;
          break;
        case 'bg4':
          this.selectedSvg = bg4Svg;
          break;
        case 'bg5':
          this.selectedSvg = bg5Svg;
          break;
        default:
          this.selectedSvg = null;
          break;
      }
    },
    changeColor(newColor) {
      this.primaryColor = newColor;
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    submit() {
      alert("submit");
    },
    adjustColor(color, amount) {
      return Math.min(255, Math.max(0, color + amount));
    },
    gotoExternalPage() {
      window.open('https://www.cheqqmate.com');
    },
    hexToRgb(hex) {
      let bigint = parseInt(hex.slice(1), 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },

    rgbToHex(r, g, b) {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    },

    getLighterAndDarkerShades(hexColor) {
      const [r, g, b] = this.hexToRgb(hexColor);

      const lighterShade = this.rgbToHex(
        this.adjustColor(r, 30),
        this.adjustColor(g, 30),
        this.adjustColor(b, 30)
      );

      const darkerShade = this.rgbToHex(
        this.adjustColor(r, -30),
        this.adjustColor(g, -30),
        this.adjustColor(b, -30)
      );

      return [lighterShade, darkerShade];
    }
  },
};
</script>

<style scoped>
body {
  width: 100%;
  margin: 0;
}
html {
  height: auto;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
.title {
    display: flex;
    text-align: right;
    border-radius: 8px;
}
.upload-area {
    height: 400px;
    width: 100%;
    /* display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 8px; */
  }
  /* .container {
    margin: 2rem;
  } */

.relative-position {
  position: relative;
}

.absolute-position {
  z-index: 5;
  position: relative;
}

.absolute-animation {
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>