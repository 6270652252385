<template lang="pug">
v-app
  v-main
    #app
      //- p currentSlide {{ currentSlide }}
      //- p pages.val:{{ this.pages[val] }}
      //- p pages:{{ pages }}
        @previous='prevSlide'
        @next='nextSlide'
      Slideshow(
        :on-end-exit='onEndExit'
        :mouse-navigation='false'
        :keyboard-navigation='false'
        :back-by-slide='true'
        :primary-color='primaryColorHex'
        :secondary-color='secondaryColorHex'
        :bg='bg'
        :bgVersion='bgVersion'
        :pages='pages.length'
        :currentPage='currentSlide'
        :statusCode='StatusCode'
      )
    v-avatar(
      size="50"
      rounded
      bordered
      border-radius="8"
      class="ma-4"
    )
      v-img(
        :src="formV2.logoUrl" 
        tile                   
        contain
        max-height="50px"
        class="br-8 justify-center ml-2 mr-2")
    
</template>

<script>
import Menu from "./components/Menu.vue";
import Vue from "vue";
import Slideshow from "@/components/Slideshow.vue";
import SlideshowPreview from "@/components/SlideshowPreview.vue";
import { mapGetters } from "vuex";
import axios from 'axios';
import bg0Svg from './templates/solid.vue';
import bg1Svg from './templates/sprinkle.vue';
import bg2Svg from './templates/simple-shiny.vue';
import bg3Svg from './templates/meteor.vue';
import bg4Svg from './templates/animated-shape.vue';
import bg5Svg from './templates/wave.vue';

// const cheqqMateServiceBaseUrl = "http://localhost:7071/api";
const cheqqMateServiceBaseUrl = process.env.VUE_APP_CHEQQMATE_APPAPI_BASE_URL;
const cheqqMateServiceConfig = {
  headers: {
    'x-functions-key': process.env.VUE_APP_CHEQQMATE_API_KEY,
    'Access-Control-Allow-Origin': process.env.VUE_APP_CHEQQMATE_APP_BASE_URL
  }
};

export default {
  components: {
    Slideshow,
    SlideshowPreview,
    bg0Svg,
    bg1Svg,
    bg2Svg,
    bg3Svg,
    bg4Svg,
    bg5Svg,
    Menu
  },
  data() {
    return {
      accountId: '',
      templateId: null,
      id: '',
      postDialog: false,
      currentSlide: -1,
      id2: '', 
      primaryColor: '154360', // Default color
      primaryColorHex: '#154360', // Default color
      secondaryColor: 'D53E59', // Default color
      secondaryColorHex: '#D53E59', // Default color
      textColor: 'f5f5f5',
      textColorHex: '#f5f5f5',
      btnTextColor: 'f5f5f5',
      btnTextColorHex: '#f5f5f5',
      bgVersion: 1,
      contrast: '',
      bg: 'bg0',
      nav: 'left',
      text1: 'center',
      slide: 'cover',
      selectedSvg: bg0Svg, // Default SVG bg
      databaseForm: {},
      mode: '',
      userId: '',
      // social graph
      title: 'CheqqMate',
      description: 'Forms, Polls and Quizzes.',
      // imageUrl: 'https://example.com/image.jpg',
      imageUrl: '../assets/CheqqMateLargeLogo.png',
      pageUrl: 'https://app.cheqqmate.com',
      StatusCode: 0
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        // Open Graph meta tags
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description },
        { property: 'og:image', content: this.imageUrl },
        { property: 'og:url', content: this.pageUrl },
        { property: 'og:type', content: 'website' },

        // Twitter Card meta tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.title },
        { name: 'twitter:description', content: this.description },
        { name: 'twitter:image', content: this.imageUrl },
        { name: 'twitter:url', content: this.pageUrl }
      ]
    };
  },
  watch: {
    currentSlide(val) {
      if(!this.templateId) {
      // const inputType = this.pages[val] ? this.pages[val].inputType: 'cover';
      const inputType = val === 99 ? 'back-cover' : (this.pages[val] ? this.pages[val].inputType : 'cover');
      this.$router.push({
        path: `/${inputType}/${this.id}?primaryColor=${this.primaryColor}&secondaryColor=${this.secondaryColor}&btnTextColor=${this.btnTextColor}&textColor=${this.textColor}&contrast=${this.contrast}&bg=${this.bg}`,
      }).catch(err => {
        // Ignore the vuex err regarding navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          logError(err);
        }
      })
      } else {
        const inputType = this.pages[val].inputType;
        // const inputType = val <= 0 ? this.pages[val].inputType: 'cover';
        this.$router.push({
        path: `/${inputType}/preview?templateId=${this.templateId}`,
      })
      }
      this.$store.dispatch('setSlidePayload', this.pages[val]);

    },
    "$route.query.primaryColor"(){
      this.$store.commit('SET_PRIMARY_COLOR', this.$route.query.primaryColor);
      this.primaryColorHex = '#'+ this.$route.query.primaryColor
      this.primaryColor = this.$route.query.primaryColor
    },
    "$route.query.secondaryColor"(){
      this.$store.commit('SET_SECONDARY_COLOR', this.$route.query.secondaryColor);
      this.secondaryColorHex = '#'+ this.$route.query.secondaryColor
      this.secondaryColor = this.$route.query.secondaryColor
    },
    "$route.query.textColor"(){
      this.$store.commit('SET_TEXT_COLOR', this.$route.query.textColor);
      this.textColorHex = '#'+ this.$route.query.textColor
      this.textColor = this.$route.query.textColor
    },
    "$route.query.btnTextColor"(){
      this.$store.commit('SET_BTN_TEXT_COLOR', this.$route.query.btnTextColor);
      this.btnTextColorHex = '#'+ this.$route.query.btnTextColor
      this.btnTextColor = this.$route.query.btnTextColor
    },
    "$route.query.contrast"(){
      this.$store.commit('SET_CONTRAST', this.$route.query.contrast);
      this.contrast = this.$route.query.contrast
    },
    "$route.query.embed"(){
      this.$store.commit('SET_EMBEDDED', this.$route.query.embed);
    },
    "$route.query.templateId"(){
      this.$store.commit('SET_TEMPLATE_ID', this.$route.query.templateId);
      this.templateId = this.$route.query.templateId;
      let template = this.getFormTemplates.filter(template => template.id === this.templateId);

      // set form to vuex
      this.$store.commit('SET_FORM_DETAILS', template[0]);
      // set form to database form locally
      this.databaseForm = template[0];
    },
    "$route.query.bg"(){
      this.bg = this.$route.query.bg || 'bg0';
    },
    "$route.query.bgVersion"(){
      this.bgVersion = this.$route.query.bgVersion || 1;
    },
    "$route.query.mode"(){
      this.mode = this.$route.query.mode;
    },
    "$route.query.userId"(){
      this.userId = this.$route.query.userId;
    }, 
    "$route.params.id"(id){
      this.id = this.$route.params.id;
      if(!this.templateId) {
        // this.readFormById(this.$route.params.id);
        this.readFormWithDelay(this.$route.params.id);
      }
    },
    databaseForm(val) {
      // console.log('databaseForm', val);
      // set meta tags
      this.title = val.name;
      this.description = val.description;
      this.pageUrl = `${window.location.origin}/cheqqmate/${this.id}`;

      this.changeSvg(val.design.theme);
      this.imageUrl = this.selectedSvg;
      this.generateSvgDataUrl();
      
      // primary color
      this.primaryColorHex = '#'+ val.design.primary;
      this.primaryColor = val.design.primary;
      this.$store.commit('SET_PRIMARY_COLOR', val.design.primary);

      // secondary color
      this.secondaryColor = val.design.secondary;
      this.secondaryColorHex = '#'+val.design.secondary;
      this.$store.commit('SET_SECONDARY_COLOR', val.design.secondary);

      // text color
      this.textColorHex = '#'+ val.design.text;
      this.textColor = val.design.text;
      this.$store.commit('SET_TEXT_COLOR', val.design.text);

      // button text color
      this.btnTextColorHex = '#'+ val.design.buttonText;
      this.btnTextColor = val.design.buttonText;
      this.$store.commit('SET_BTN_TEXT_COLOR', val.design.buttonText);

      // background
      this.bg = val.design.theme || 'bg0';
      this.bgVersion = val.design.themeVersion || 1;
    }

  },
  computed: {
    ...mapGetters(['getFormTemplates']),
    formV2() {
      return this.$store.state.form;
    },
    pages() {
        //given table of contents, get pages
      var pages = [];
      this.$store.state.form.tableOfContents?this.$store.state.form.tableOfContents.forEach(section => {
        section.pages.forEach(page => {
          pages.push(page);
        });
      }):null;
      return pages || [];
        // return this.$store.state.form.tableOfContents.flatMap(section => section.pages);
    },
    numberOfPages() {
      return this.pages.length;
    },
  },
  methods: {
    onEndExit() {},
    nextSlide() {
      if(this.currentSlide === this.pages.length - 1) { // last slide  && currentPage !== pages-1
        this.currentSlide = 99;
      } else {
        this.currentSlide++;
      }
    },
    prevSlide() {
      this.currentSlide--;
    },
    readFormWithDelay(formId) {
      // set loading state
      this.$store.commit('START_LOADING')
      setTimeout(() => {
        this.readFormById(formId);
      }, 2500);
    },
    readFormById(formId) {
      // works for all assets: forms, quizzes, polls. They use the same class - AssetDetails
      var endpoint = this.mode === 'draft' ? `/getDraftFormById?FormId=${formId}&UserEmail=${this.userId}` : `/getFormById?FormId=${formId}`;
      axios
      .get(
        `${cheqqMateServiceBaseUrl}${endpoint}`,
        cheqqMateServiceConfig,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if(res.data) {
          //save to vuex, so that it persists, instead of consistently calling api
        // remove 'hidden' pages from table of contents
          res.data.Form.tableOfContents.forEach(section => {
            section.pages = section.pages.filter(page => !page.isHidden);
          });
          this.$store.commit('SET_FORM_DETAILS', res.data.Form);

          // set form to database form
          if(!this.mode) {
            this.databaseForm = res.data.Form;
          }
          this.StatusCode = res.status;
          // reset loading state
          this.$store.commit('FINISH_LOADING')
        }
      })
      .catch(error => {
        if (error.response) {
          this.StatusCode = error.response.status;
          // reset loading state
          // this.$store.commit('FINISH_LOADING')
        }
      });
    },
    generateSvgDataUrl() {
      // Create a new Vue instance with the SelectedSvg component
      const SvgConstructor = Vue.extend(this.selectedSvg);
      const svgInstance = new SvgConstructor({
        propsData: {
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor,
          version: this.bgVersion
        }
      });

      // Mount the SVG instance to an off-DOM element
      svgInstance.$mount();
      const svgElement = svgInstance.$el;

      // Serialize the SVG element to a string
      const svgString = new XMLSerializer().serializeToString(svgElement);
      const encodedData = encodeURIComponent(svgString);

      // Set the imageUrl to the generated data URL
      this.imageUrl = `data:image/svg+xml;charset=utf-8,${encodedData}`;

      // Destroy the SVG instance
      svgInstance.$destroy();
    },
    changeSvg(bgName) {
      switch (bgName) {
        case 'solid':
          this.selectedSvg = bg0Svg;
          break;
        case 'blob':
          this.selectedSvg = bg1Svg;
          break;
        case 'blob-scene':
          this.selectedSvg = bg2Svg;
          break;
        case 'blob-scatter':
          this.selectedSvg = bg3Svg;
          break;
        case 'polygon-scatter':
          this.selectedSvg = bg4Svg;
          break;
        case 'wave':
          this.selectedSvg = bg5Svg;
          break;
        default:
          this.selectedSvg = bg0Svg;
          break;
      }
    },
  },
  created() {
    this.primaryColorHex = '#'+ this.$route.query.primaryColor;
    this.primaryColor = this.$route.query.primaryColor;
    this.secondaryColor = this.$route.query.secondaryColor;
    this.secondaryColorHex = '#'+this.$route.query.secondaryColor;
    this.textColorHex = '#'+ this.$route.query.textColor;
    this.textColor = this.$route.query.textColor;
    this.btnTextColorHex = '#'+ this.$route.query.btnTextColor;
    this.btnTextColor = this.$route.query.btnTextColor;
    this.bg = this.$route.query.bg || 'bg0';


  },
  mounted() {

    // this.$router.push("/cheqqmate/0");
    // http://localhost:8080/cheqqmate/0?primaryColor=ffff
    this.$store.dispatch('setSlidePayload', this.pages[this.currentSlide]);

    if(this.$route.params.id) {
      this.id = this.$route.params.id;
      // this.readFormById();
    }
    // generate svg data url
    // this.generateSvgDataUrl();
  }
};
</script>

<style lang="scss">
body {
  width: 100%;
  margin: 0 0;
}
html {
  height: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  z-index: 999;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
  margin: 20px 20px;
}
.right-1 {
  right: 230px;
  margin: 17px 10px;
}
.border-radius {
  border-radius: 15px;
}
.background {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100vw;
  height: 100vh;

  /* background-color: transparent!important; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}
</style>
